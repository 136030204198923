import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/VContrattoCO'

class API {
    @call({ url: `${BASE_URL}/GetVContrattiCo` })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<VContrattoCO>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveVContrattoCo` })
    static async save<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveVContrattoCO>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
