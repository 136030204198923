export enum enmInputTextCoercion {
    hasNoSpecialCharacters = 1,
    hasOnlyDigits = 2,
    hasOnlyAlphanumeric = 3,
    hasOnlyAlphanumericCC = 4,
    hasOnlyDecimal = 5,
    none = 0,
}

export enum enmInputStatus {
    onError,
    onWarning,
    none,
}

export enum enmValidatorType {
    popup,
    messageBelow,
}

export enum enmValidatorMessage {
    capNonValido = 'CAP non valido',
}

export enum enmColumns {
    abi = 1,
    codici = 2,
    comuni = 3,
    idIntermediarioUniemens = 4,
    cittadinanza = 5,
    codiceStatoEstero = 6,
    comuneTipo1 = 7,
    comuneTipo2 = 8,
    idIntermediarioInailLul = 9,
    idIntermediarioComunicazioni = 10,
    idIntermediarioFiscale = 11,
    scaglioniIrpef = 12,
    denominazione = 13,
    comuniAttivita = 14,
    descrizioni = 15,
    none = 0,
}

export enum enmAutoFill {
    fillDate,
    FillDateMMYYYY,
    upperCase,
    fillWithZeros,
}

export enum enmMsgNotifications {
    codiceLavAutonomi = 4,
    codiceEntePresente = 7,
    codiceSedeInpsErrato = 8,
    codiceBancaErrato = 10,
    codiceInformativoCittadinanza = 66,
    codiceCompilazioneExtracomunitario = 68,
    codiceImpegnoDatoreLavoro = 69,
    codiceDittaGiaPresente = 359,
    codiceOperatoreRangeDitte = 360,
    codiceUnitaProdGiaPresente = 361,
    codiceGestioneValidita = 362,
    codFiscErratoOkGoAnnullaBack = 365,
    codiceErroreDateInizioFine = 380,
}

export enum enmButtonEvents {
    save = 0,
    saveandstop = 40,
    cancel = 1,
    redA = 2,
    redM = 3,
    redR = 4,
    blueA = 5,
    blueM = 6,
    blueR = 7,
    firstElement = 8,
    previousElement = 9,
    nextElement = 10,
    lastElement = 11,
    print = 12,
    communications = 13,
    payslip = 14,
    archivi = 20,
    tabelle = 21,
    note = 30,
    stampe = 31,
    cedolini = 32,
    assistente = 33,
}
//#region Modalita funzionamento pagine
export enum enmDipendentiMode {
    insert = 0,
    mod = 1,
}

export enum enmDittaMode { //TODO controllare nel refactoring di Ditta
    insert = 0,
    insertUP = 1,
    mod = 2,
}

//#endregion

export class EnumProvider {
    static getEnums(): any {
        return {
            enmInputTextCoercion,
            enmInputStatus,
            enmValidatorType,
            enmColumns,
            enmAutoFill,
            enmDipendentiMode,
        }
    }
}
