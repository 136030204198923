import axios, { AxiosResponse } from 'axios'
import { get } from 'lodash'

import appConfig from '@/app.config'
import AuthService from '@/typescript/Service/auth-service'
import { MessageBox } from '@/scripts/services/utility.js'
import { useGlobalStore } from '@/stores'
import { endpoints } from '@/scripts/services/endpoints.js'
import { useStorePermissions } from '@/scripts/stores/Permissions/pagePermission.js'
import { getProfileUrl } from '@/api/APIAuth'

type Token = {
    access_token: string
    token_type: string
}

const tokenStoreKey = `oidc.user:${appConfig.authorityUrl}:${appConfig.clientId}`
const baseURL = import.meta.env.VITE_API_URL
const http = axios.create({
    baseURL,
    headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
})

http.interceptors.request.use(config => {
    // const globalStore = useGlobalStore()
    // const controller = new AbortController()
    // if (globalStore.state.stopRoute) controller.abort()
    // config.signal = controller.signal
    const operatore = useStorePermissions().operatori.find(item => item.attivo)
    if (operatore) config.headers.codice_operatore = operatore.codice
    if (import.meta.env.VITE_LOGIN_ENDPOINT_MP) {
        config.headers.is_dataperiodoelaborazione_studio_mesecorrente = JSON.stringify(
            location.origin !== import.meta.env.VITE_LOGIN_ENDPOINT_MP,
        )
    }
    config.headers.Authorization = null
    let token: null | string | Token = localStorage.getItem(tokenStoreKey)
    if (token) {
        token = JSON.parse(token) as Token
        const auth = `${token.token_type} ${token.access_token}`
        config.headers.Authorization = auth
    }
    return config
})

let error404RecentlyDone = false

http.interceptors.response.use(
    async response => {
        if (response.status === 200 && response.data.responseStatus) {
            const responseStatus = response.data.responseStatus
            if (responseStatus.isSuccessful) {
                if (responseStatus?.alertMessages?.length) {
                    await MessageBox.warning(responseStatus.alertMessages[0].message)
                }
                // if (responseStatus?.customMessagesAfterValidation?.length) {
                //     await MessageBox.warning(
                //         `${response.data.responseStatus.customMessagesAfterValidation[0].message}` +
                //             `<br><br>TraceId: ${responseStatus.traceIdentifier}`,
                //     )
                // }
            } else {
                if (!responseStatus.fieldErrors && response.config.url !== endpoints.GetProfile) {
                    await MessageBox.error(
                        `Errore ${responseStatus.code}: ${responseStatus.message}` +
                            `<br><br>TraceId: ${responseStatus.traceIdentifier}`,
                    )
                }
            }
        }
        return response
    },
    async error => {
        const auth = AuthService.getInstance()
        const globalStore = useGlobalStore()
        switch (error.response.status) {
            case 401:
                await auth.login()
                return Promise.reject(error)
            case 404:
                if (!error404RecentlyDone) {
                    await MessageBox.error(error.response.data.responseStatus.message)
                    if (error.response.config.url === getProfileUrl) {
                        auth.logout()
                        return
                    }
                    error404RecentlyDone = true
                    globalStore.loadingUnset()
                    setTimeout(() => (error404RecentlyDone = false), 3000)
                }
                return error.response
            case 406:
                if (error.response.config.url !== getProfileUrl) {
                    const endpoint =
                        location.origin === import.meta.env.VITE_LOGIN_ENDPOINT
                            ? import.meta.env.VITE_LOGIN_ENDPOINT_MP
                            : import.meta.env.VITE_LOGIN_ENDPOINT
                    if (endpoint && endpoint !== location.origin) {
                        // globalStore.state.stopRoute = true
                        const msg =
                            `Errore periodo elaborazione.` +
                            `<br><br>Messaggio di errore da definire.`
                        await MessageBox.error(msg)
                        auth.logout(endpoint)
                    }
                }
                return error.response
            default:
                const responseStatus = error.response.data?.responseStatus
                    ? error.response.data?.responseStatus
                    : error.response.data?.title && error.response.data?.traceId
                      ? { traceIdentifier: error.response.data?.traceId }
                      : { traceIdentifier: 'Not available' }

                const msg =
                    `Errore ${error.response.status}: contattare l'assistenza Essepaghe per` +
                    ` maggiori info.<br><br>TraceId: ${responseStatus.traceIdentifier}`
                await MessageBox.error(msg)
                return error.response
        }
    },
)

//TODO: quando BANCHE, COMUNI ed ENTI sarranno state modularizzate, "call" verrà sostituita da "call1"
type Method = 'delete' | 'get' | 'post' | 'put' | 'save'
type CallSign = { url: string; method?: Method; defaultPayload?: Payload }
type FnApply = {
    callBack: (payload: any, response: any) => any
    noHttp: (payload: any) => any
}
function call(args: CallSign) {
    return function (target: any, key: string, descriptor: PropertyDescriptor) {
        descriptor.value = async <T, R>(payload: T = {} as T) => {
            try {
                let _payload: any = {
                    ...(args.defaultPayload || {}),
                    periodoDiRiferimento: { ...useGlobalStore().state.periodoElab },
                    ...payload,
                }

                if (args?.method === 'delete') _payload = { data: _payload }

                const _http = get(http, args.method || 'post', http.post)
                return _http!<T, AxiosResponse<T, R>>(args.url, _payload)
            } catch (error) {
                console.log(error)
            }
        }
        return descriptor
    }
}
// x VCODDES
function call1(args: CallSign) {
    return function (target: any, key: string, descriptor: PropertyDescriptor) {
        const fn = descriptor.value
        descriptor.value = async <T, R>(payload: T = {} as T, noHttp: boolean) => {
            const fnApply: FnApply = await fn.apply()
            const _noHttp = !!(noHttp !== undefined ? noHttp : !!fnApply?.noHttp)
            if (_noHttp) return await fnApply?.noHttp(payload)
            try {
                const _payload = {
                    ...(args.defaultPayload || {}),
                    periodoDiRiferimento: { ...useGlobalStore().state.periodoElab },
                    ...payload,
                }
                const _http = get(http, args.method || 'post', http.post)
                const response = await _http!<T, AxiosResponse<T, R>>(args.url, _payload)
                return fnApply?.callBack ? await fnApply.callBack(payload, response) : response
            } catch (error) {
                console.log(error)
            }
        }
        return descriptor
    }
}

export { call, call1, http }
