import { GLOBALS } from '@/constants'
import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    GROUP: 'uniemensCollab',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'UNIEMENS LAV SPETT',
    CODPERMESSO: 'RII_COLL',
}

function propsFn(route) {
    const params = {
        ...route.params,
        year: Number(route.params.year),
        month: Number(route.params.month),
        day: route.params.day ? 1 : Number(route.params.day),
        dittaId: Number(route.params.dittaId),
        dittaPadreId: Number(route.params.dittaPadreId),
        mod: route.params.mod ?? GLOBALS.DATA_MODE.MOD,
        id: Number(route.params.id),
    }

    return params
}

export const SETTINGS = Object.freeze({
    DETTAGLIO: {
        TITLE: 'Dettaglio lavoratore autonomi spettacolo - Uniemens - Collaboratore - Gestione - Paghe',
        LABEL: 'Dettaglio lavoratore autonomi spettacolo',
        PATH: 'uniemensCollabSpettac/:year/:month/:day/:dittaId/:dittaPadreId/:id/:mod',
        NAME: 'uniemensCollabSpettacoloDettaglio',
        TAB: { ...tabPropsDefault },
    },
    GENERALE: {
        TITLE: '',
        LABEL: 'Generale',
        PATH: 'generaleSpettacolo',
        NAME: 'uniemensSpettacoloGenerale',
        TAB: { ...tabPropsDefault },
    },
    IMPONIBILE: {
        TITLE: '',
        LABEL: 'Imponibile / Contributi',
        PATH: 'imponibileSpettacolo',
        NAME: 'uniemensSpettacoloImponibile',
        TAB: { ...tabPropsDefault },
    },
    SETTIMANA: {
        TITLE: '',
        LABEL: 'Settimane / Differenze accredito',
        PATH: 'settimaneSpettacolo',
        NAME: 'uniemensSpettacoloSettimane',
        TAB: { ...tabPropsDefault },
    },
    SPORT_SPETTACOLO: {
        TITLE: '',
        LABEL: 'Sport / Spettacolo',
        PATH: 'SportSpettacoloSP',
        NAME: 'uniemensSPSportSpettacolo',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            props: propsFn,
            component: pagheViews.uniemens.collaboratore.dettaglioSpettacolo.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    label: SETTINGS.GENERALE.LABEL,
                    path: SETTINGS.GENERALE.PATH,
                    name: SETTINGS.GENERALE.NAME,
                    tab: SETTINGS.GENERALE.TAB,
                    component:
                        pagheViews.uniemens.collaboratore.dettaglioSpettacolo.childs.Generale,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNC01S',
                    },
                    orderWizard: 0,
                    props: propsFn,
                },
                {
                    label: SETTINGS.IMPONIBILE.LABEL,
                    path: SETTINGS.IMPONIBILE.PATH,
                    name: SETTINGS.IMPONIBILE.NAME,
                    tab: SETTINGS.IMPONIBILE.TAB,
                    component:
                        pagheViews.uniemens.collaboratore.dettaglioSpettacolo.childs.Imponibile,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNC02S ',
                    },
                    orderWizard: 1,
                    props: propsFn,
                },
                {
                    label: SETTINGS.SETTIMANA.LABEL,
                    path: SETTINGS.SETTIMANA.PATH,
                    name: SETTINGS.SETTIMANA.NAME,
                    tab: SETTINGS.SETTIMANA.TAB,
                    component:
                        pagheViews.uniemens.collaboratore.dettaglioSpettacolo.childs.Settimana,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNC03S',
                    },
                    orderWizard: 2,
                    props: propsFn,
                },
                {
                    label: SETTINGS.SPORT_SPETTACOLO.LABEL,
                    path: SETTINGS.SPORT_SPETTACOLO.PATH,
                    name: SETTINGS.SPORT_SPETTACOLO.NAME,
                    tab: SETTINGS.SPORT_SPETTACOLO.TAB,
                    component: pagheViews.uniemens.collaboratore.dettaglioSpettacolo.childs.Sport,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNC04S',
                    },
                    orderWizard: 2,
                    props: propsFn,
                },
            ],
        },
    ],
}
