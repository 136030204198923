import { useGlobalStore } from '@/stores'
import gruppiRisultatiViews from '@/modules/paghe/views/tabelle/gruppiRisultati'

const tabPropsDefault: TabProps = {
    GROUP: 'GruppiRisultatiCodice',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'Gruppi risultati',
    CODVOCEMENU: '000030',
    CODPERMESSO: ['TABELLE_SP', 'TABELLE_STUDIO'], //DOVREBBE ESSERE UN ARRAY
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route) {
    return {
        ...route.params,
        year: Number(route.params?.year ?? useGlobalStore().state.periodoElab!.year),
        month: Number(route.params?.month ?? useGlobalStore().state.periodoElab!.month),
        attualeCheck: Number(route.params?.attualeCheck ?? 1),
        allValidate: Number(route.params?.allValidate ?? 0),
    }
}

export const SETTINGS = Object.freeze({
    DETTAGLIO: {
        TITLE: 'Tabelle di domino - Gestione - Gruppi risultati',
        LABEL: 'Gruppi risultati',
        PATH: 'gruppiRisultati/:year?/:month?/:attualeCheck?/:allValidate?/',
        NAME: 'DettaglioGruppiRisultati',
        TAB: { ...tabPropsDefault },
    },
    GRUPPIRISULTATI: {
        TITLE: 'Tabelle di domino - Gestione - Gruppi risultati',
        LABEL: 'Gruppi risultati',
        PATH: 'gruppiRisultati',
        NAME: 'GruppiRisultati',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            component: gruppiRisultatiViews.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                defaultProps: {
                    year: () => useGlobalStore().state.periodoElab!.year,
                    month: () => useGlobalStore().state.periodoElab!.month,
                    attualeCheck: 1,
                    allValidate: 0,
                },
            },
            props: propsFn,
            children: [
                {
                    path: SETTINGS.GRUPPIRISULTATI.PATH,
                    name: SETTINGS.GRUPPIRISULTATI.NAME,
                    component: gruppiRisultatiViews.childs.GruppiRisultati,
                    meta: {
                        title: SETTINGS.GRUPPIRISULTATI.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TGRRI',
                    },
                    props: propsFn,
                },
            ],
        },
    ],
}
