import anagrafiche from './anagrafiche'
import exports from './exports'
import mensilita from './mensilita'
import movimenti from './movimenti'
import risultati from './risultati'
import tabelle from './tabelle'
import uniemens from './uniemens'
import home from './home'

type PageRoute = { routes: Route[]; SETTINGS: { [key: string]: RouteSettings } }

//TODO: vedere tipizzazione props in Route
export const routes: Route[] = []
const pagePoutes: { [key: string]: PageRoute }[] = [
    anagrafiche,
    exports,
    home,
    movimenti,
    mensilita,
    risultati,
    tabelle,
    uniemens,
]
pagePoutes.forEach((pageRoute: { [key: string]: PageRoute }) => {
    Object.values(pageRoute).forEach((route: PageRoute) => routes.push(...route.routes))
})

export default { anagrafiche, exports, home, mensilita, movimenti, risultati, tabelle, uniemens }
