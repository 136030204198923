import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'

import appConfig from '@/app.config'
import { useGlobalStore } from '@/stores'
import { useStorePermissions } from '@/scripts/stores/Permissions/pagePermission'

type Token = {
    access_token: string
    token_type: string
}
export default class Scheduler {
    private conn: HubConnection
    private globalStore: any
    private permissionsStore: any
    private operatore: { attivo: boolean; codice: string; userName: string } | undefined

    constructor() {
        this.globalStore = useGlobalStore()
        this.permissionsStore = useStorePermissions()
        this.operatore = this.permissionsStore.operatori.find((item: any) => item.attivo)
        this.conn = new HubConnectionBuilder()
            .withUrl(`${import.meta.env.VITE_API_URL}/schedulerHub`, {
                headers: { codice_operatore: this.operatore!.codice },
                accessTokenFactory: () => {
                    const tokenKey = `oidc.user:${appConfig.authorityUrl}:${appConfig.clientId}`
                    const tokenString: null | string = localStorage.getItem(tokenKey)
                    const token: Token = JSON.parse(tokenString!)
                    return token.access_token
                },
            })
            .build()
    }

    public async start() {
        await this.conn.start()
        const group =
            this.globalStore.state.codService! +
            this.globalStore.state.codStudio! +
            this.globalStore.state.codOperatore
        this.conn.invoke('AggiungiAlGruppo', group || '')
        this.conn.off('RiceviNotificaSchedulazione')
        this.conn.on(
            'RiceviNotificaSchedulazione',
            (id: number, success: boolean, message: string) => {
                if (success) this.globalStore.state.taskCompletati.push(id)
            },
        )
    }

    public async stop() {
        this.conn.stop()
    }
}
