<template>
    <div class="d-flex align-items-start hmodalOthersLevelMenu">
        <!-- // TAB verticali con le voci di secondo livello -->
        <div
            id="v-pills-tab"
            class="nav flex-column nav-pills me-3"
            role="tablist"
            aria-orientation="vertical"
        >
            <button
                v-for="(item, index) in getMenuItemsByPermission"
                :id="'v-pills-' + item.codVoceMenu + '-tab'"
                :key="index"
                :class="{ active: index == activeItem }"
                :clickCount="index == 0 ? 1 : 0"
                class="nav-link"
                data-bs-toggle="pill"
                :data-bs-target="'#v-pills-' + item.codVoceMenu"
                type="button"
                role="tab"
                :aria-controls="'v-pills-' + item.codVoceMenu"
                :aria-selected="index == activeItem ? 'true' : 'false'"
                :aria-label="'Sezione ' + item.descrizione"
                :tabindex="index == activeItem ? '' : '-1'"
                @click="showSelectedMenu(index, item.codVoceMenu)"
            >
                {{ item.descrizione }}
            </button>
        </div>
        <!-- // CONTENUTO: intestazioni e link associati -->
        <div
            id="v-pills-tabContent"
            class="tab-content w-100"
        >
            <div
                role="tabpanel"
                class="tab-pane fade show active"
                :aria-labelledby="'v-pills-' + arialabelledby"
                tabindex="0"
            >
                <nav role="navigation">
                    <div class="row">
                        <div
                            v-for="level3 in selected2ndLevel"
                            :key="level3.codVoceMenu"
                            class="col"
                        >
                            <h3>{{ level3.descrizione }}</h3>

                            <ul class="nav leaf-list">
                                <!--v-for="level4 in level3.vociFiglie"-->
                                <li
                                    v-for="level4 in getLastLevelItems(level3.vociFiglie)"
                                    :key="level4.codVoceMenu"
                                    :class="
                                        selected2ndLevel.length == 1
                                            ? 'col col-xs-12 col-md-4'
                                            : 'col-12'
                                    "
                                    class="list-item"
                                    :style="{
                                        cursor: disableInfoIcon(level4) ? 'not-allowed' : null,
                                    }"
                                    @click="clickMenu(level4)"
                                >
                                    <a
                                        href="#"
                                        role="link"
                                        :class="{ disabled: disableInfoIcon(level4) }"
                                        class="nav-link"
                                        :title="level4.descrizione"
                                        @click.prevent
                                    >
                                        {{ level4.descrizione }}
                                        <v-tooltip right>
                                            <template #activator="{ props }">
                                                <v-icon
                                                    v-if="disableInfoIcon(level4)"
                                                    v-bind="props"
                                                    :class="`${disableColor(level4)} mb-1 ms-1`"
                                                    :icon="disableIcon(level4)"
                                                    size="x-small"
                                                    :style="{ cursor: 'pointer' }"
                                                />
                                            </template>
                                            <span v-if="level4.codAutoMenu === 'D'">
                                                Funzionalità con attivazione richiesta
                                            </span>
                                            <span v-else>
                                                Hai già un tab attivo per questo link.<br />
                                                Chiudi il menu e usa il tab di navigazione
                                                principale.
                                            </span>
                                        </v-tooltip>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import { cloneDeep } from 'lodash'
import { createPromiseDialog } from 'vue-promise-dialogs'
import { useTabStore } from '@/stores'
import { useStorePermissions } from '@/scripts/stores/Permissions/pagePermission.js'
import { mapMenuModals } from '@paghe/modals'

export default {
    name: 'OtherLevelItems',
    components: {},
    props: {
        menuModel: {
            default: () => [],
            type: Array,
        },
    },
    emits: ['close-hmenu-modal'],
    setup() {
        const tabStore = useTabStore()

        const { menu } = storeToRefs(useStorePermissions())
        const focusTrap = ref(null)

        return {
            tabStore,
            menu,
            focusTrap,
        }
    },
    data() {
        return {
            open: true,
            activeItem: 0,
            arrActiveTabs: [],
            arialabelledby: '000000',
        }
    },
    computed: {
        getMenuItemsByPermission() {
            //secondoi livello in ordine assoluto
            let filteredMenu = []
            if (this.menuModel.length > 0) {
                //livello 1
                filteredMenu = this.menuModel.filter(
                    item => item.codAutoMenu == 'A' || item.codAutoMenu == 'D',
                )
            }
            return filteredMenu
        },
        selected2ndLevel() {
            //seleziona le voci figlie del secondo livello
            if (!this.menuModel[this.activeItem].vociFiglie) return []
            return this.menuModel[this.activeItem].vociFiglie.filter(
                item => item.codAutoMenu == 'A' || item.codAutoMenu == 'D',
            )
        },
    },
    watch: {
        menuModel() {
            this.activeItem = 0
        },
    },
    created() {
        // abilito la visualizzazione del primo menu e delle
        //this.showSelectedMenu(this.activeItem);
        // verifico i tab attivi e disattivo le voci di menu corrispondenti. L'utente dovrà usare i tab corrispondenti per poter accedere alla voci di menu
        // if (this.tabStore && this.tabStore.state.tabs.length > 0) {
        //     this.arrActiveTabs = [...this.tabStore.state.tabs]
        // }

        // WCAG: workaround sui bottoni verticali di secondo livello. Al primo caricamento simulo un click sul primo bottone per bypassare un'anomalia
        this.$nextTick(() => {
            this.clickFirstItem()
        })
    },
    methods: {
        disableColor(level4) {
            return level4.codAutoMenu === 'D' ? 'text-error' : 'text-warning'
        },
        disableIcon(level4) {
            return `fas fa-${level4.codAutoMenu === 'D' ? 'ban' : 'circle-exclamation'}`
        },
        disableInfoIcon(level4) {
            return level4.codAutoMenu === 'D' || this.tabStore.isTabOpen(level4.codVoceMenu)
        },
        async clickMenu(level4) {
            if (this.disableInfoIcon(level4) || level4.codAutoMenu !== 'A') return
            if (mapMenuModals.has(level4.codVoceMenu)) {
                createPromiseDialog((await mapMenuModals.get(level4.codVoceMenu)()).default)({})
            } else {
                if (!this.tabStore.state.tabs[level4.codVoceMenu]) {
                    const route = this.tabStore.getRoute(level4.codVoceMenu)
                    if (route) {
                        const params = cloneDeep(route?.meta?.defaultProps) || {}
                        Object.getOwnPropertyNames(params).forEach(param => {
                            if (typeof params[param] === 'function')
                                params[param] = params[param](level4.codVoceMenu)
                        })
                        this.$router.replace({ name: route.name, params })
                        this.$emit('close-hmenu-modal', true)
                    }
                }
            }
        },
        getLastLevelItems(items) {
            //item corrisponde all'array vociFiglie
            if (!items) return []
            return items.filter(item => item.codAutoMenu == 'A' || item.codAutoMenu == 'D')
        },
        showSelectedMenu(index, codVoceMenu) {
            //--
            this.activeItem = index
            this.arialabelledby = codVoceMenu
        },
        clickFirstItem() {
            const btnLiv2 = document.querySelector('#v-pills-tab > button') // primo bottone delle voci verticali (secondo livello)
            const btnLiv1 = document.querySelector('.hmodalFirtsLevelMenu > button') // primo bottone delle voci orizzontali (primo livello)
            btnLiv2.click()
            btnLiv1.click()
            btnLiv1.focus()
        },
    },
}
</script>

<style lang="scss">
.hmodalOthersLevelMenu {
    #v-pills-tabContent {
        overflow-y: auto;
        max-height: 500px;
    }
}
</style>
