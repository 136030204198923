import { useGlobalStore } from '@/stores'
import PromemoriaViews from '@paghe/views/anagrafiche/promemoria'

const tabPropsDefault: TabProps = {
    GROUP: 'PromemoriaCodice',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'PROMEMORIA',
    CODVOCEMENU: '000199',
    CODPERMESSO: 'LOG',
}

export const SETTINGS = Object.freeze({
    DETTAGLIO: {
        TITLE: 'Anagrafica - Gestione -Promemoria',
        LABEL: 'Promemoria',
        PATH: 'promemoria/:year?/:month?/',
        NAME: 'DettaglioPromemoria',
        TAB: { ...tabPropsDefault },
    },
    PROMEMORIA_OPERATORE: {
        TITLE: 'Anagrafica - Gestione -Promemoria',
        LABEL: 'Promemoria',
        PATH: 'promemoria',
        NAME: 'Promemoria',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            component: PromemoriaViews.dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                defaultProps: {
                    year: () => useGlobalStore().state.periodoElab!.year,
                    month: () => useGlobalStore().state.periodoElab!.month,
                    attualeCheck: 1,
                    allValidate: 0,
                },
            },

            children: [
                {
                    path: SETTINGS.PROMEMORIA_OPERATORE.PATH,
                    name: SETTINGS.PROMEMORIA_OPERATORE.NAME,
                    component: PromemoriaViews.childs.Promemoria,
                    meta: {
                        title: SETTINGS.PROMEMORIA_OPERATORE.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'PROME',
                    },
                },
            ],
        },
    ],
}
