import { useGlobalStore } from '@/stores'
import retribuzioniViews from '@paghe/views/tabelle/retribuzioni'

const tabPropsDefault: TabProps = {
    GROUP: 'RetribuzioneCodice',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'Retribuzione',
    CODVOCEMENU: '000034',
    CODPERMESSO: ['TABELLE_SP', 'TABELLE_STUDIO'],
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route: any) {
    return {
        ...route.params,
        year: Number(route.params?.year ?? useGlobalStore().state.periodoElab!.year),
        month: Number(route.params?.month ?? useGlobalStore().state.periodoElab!.month),
        attualeCheck: Number(route.params?.attualeCheck ?? 1),
        allValidate: Number(route.params?.allValidate ?? 0),
    }
}

export const SETTINGS = Object.freeze({
    DETTAGLIO: {
        TITLE: ' Tabelle di domino - Gestione - Retribuzioni convenzionali',
        LABEL: 'Retribuzioni',
        PATH: 'tabelleRetribuzioni/:year?/:month?/:attualeCheck?/:allValidate?/',
        NAME: 'DettaglioRetribuzioni',
        TAB: { ...tabPropsDefault },
    },
    RETRIBUZIONI: {
        TITLE: 'Tabelle di domino - Gestione - Retribuzioni convenzionali ',
        LABEL: 'Retribuzioni',
        PATH: 'retribuzioni',
        NAME: 'Retribuzioni',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            component: retribuzioniViews.dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                defaultProps: {
                    year: () => useGlobalStore().state.periodoElab!.year,
                    month: () => useGlobalStore().state.periodoElab!.month,
                    attualeCheck: 1,
                    allValidate: 0,
                },
            },
            props: propsFn,
            children: [
                {
                    path: SETTINGS.RETRIBUZIONI.PATH,
                    name: SETTINGS.RETRIBUZIONI.NAME,
                    component: retribuzioniViews.childs.retribuzione,
                    meta: {
                        title: SETTINGS.RETRIBUZIONI.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TRECO',
                    },
                    props: propsFn,
                },
            ],
        },
    ],
}
