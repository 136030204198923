import { cloneDeep } from 'lodash'

export function useBaseTable() {
    const baseTableOptions = (): BaseTableOptions => {
        return cloneDeep({
            filtro: '',
            sortBy: [{ key: 'codice', order: 'asc' }],
            selectedKeys: true,
        })
    }

    return {
        baseTableOptions,
    }
}
