import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/VProprieta'

class API {
    @call({ url: `${BASE_URL}/GetVProprieta` })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<VProprieta>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveVProprieta` })
    static async save(
        payload: SaveVProprieta,
    ): Promise<AxiosResponse<BasicResponsePayload<VProprieta>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
