import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/VIrpefDetrAltroCarico'

class API {
    @call({ url: `${BASE_URL}/GetVIrpefDetrAltroCarico` })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<vIrpefDetrAltroCarico>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveVIrpefDetrAltroCarico` })
    static async save<T extends SaveIrpefDetrAltroCarico>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<vIrpefDetrAltroCarico>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
