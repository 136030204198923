import { AxiosResponse } from 'axios'

import { call } from '@/scripts/lib/http'

const BASE_URL = '/Schedulazione'

class API {
    @call({ url: `${BASE_URL}/RiiSchedulerJob` })
    static async actionCronExpressionEdit<T>(
        payload?: T,
    ): Promise<AxiosResponse<BasicResponsePayload<T>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/DeleteExecutionJob` })
    static async actionDelete<T>(
        payload?: T,
    ): Promise<AxiosResponse<BasicResponsePayload<T>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/ResumeExecutionJob` })
    static async actionResume<T>(
        payload?: T,
    ): Promise<AxiosResponse<BasicResponsePayload<T>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/StopExecutionJob` })
    static async actionStop<T>(
        payload?: T,
    ): Promise<AxiosResponse<BasicResponsePayload<T>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/GetExecutionJobs` })
    static async get<T, R>(payload?: T): Promise<AxiosResponse<BasicResponsePayload<R>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/GetSchedulerOutputFile` })
    static async download<T, R>(
        payload?: T,
    ): Promise<AxiosResponse<BasicResponsePayload<R>> | null> {
        return null
    }
}

export default {
    actionCronExpressionEdit: API.actionCronExpressionEdit<SchedulazioneActionPayload>,
    actionDelete: API.actionDelete<SchedulazioneActionPayload>,
    actionResume: API.actionResume<SchedulazioneActionPayload>,
    actionStop: API.actionStop<SchedulazioneActionPayload>,
    get: API.get<SchedulazioneJobsPayload, SchedulazioneJobs>,
    downloadFile: API.download<SchedulazioneDownloadPayload, SchedulazioneDownload>,
}
