import { cloneDeep, get, has, orderBy, set } from 'lodash'

/**
 * Converte una stringa base 64 in un oggetto Blob
 * @param base64String Stringa in base 64
 * @param contentType Il type (es. text/html, ...)
 * @return L'oggetto blob
 */
function base64ToBlob(base64String: string, contentType: string): Blob {
    const byteCharacters = atob(base64String)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: contentType })
}

/**
 * Download file
 * @param content Il contenuto da scaricare
 * @param contentType Il type (es. text/html, ...)
 * @param filename Il nome assegnato al file scaricato
 */
function downloadFile(content: any, contentType: string, filename: string) {
    let type = null
    switch (contentType) {
        case 'xls':
            type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            break
        default:
            type = contentType
    }
    const blob =
        typeof content === 'string'
            ? base64ToBlob(content, type)
            : new Blob([JSON.stringify(content)], { type: contentType })
    const link = document.createElement('a')
    link.download = filename
    link.style.display = 'none'
    link.href = window.URL.createObjectURL(blob)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

/**
 * Filtra e ritorna un nuovo array di oggetti matchando sui valori delle chiavi di primo livello
 * specificate in 'keys' (lista o stringa)
 * NOTA: "old style" al posto di 'forEach' e 'for on' per velocizzare le operazioni
 * @param {string} filter filtro di ricerca
 * @param {Object<T>[]} items source array di tipo T
 * @param {string | string[]} keys chiavi dell'oggetto su cui applicare la ricerca
 * @return {Array<T>[]}
 */
function filterObjectList<T>(filter: string, items: T[], keys: string | string[]): T[] {
    if (!filter) return items
    filter = filter.toLocaleLowerCase()
    if (typeof keys === 'string') keys = [keys]
    const keysLength = keys.length
    const filtered = []
    for (let i1 = 0, l1 = items.length; i1 < l1; ++i1) {
        for (let i2 = 0; i2 < keysLength; ++i2) {
            let value = items[i1][keys[i2] as keyof T] as number | string
            if (!value) continue
            if (typeof value !== 'string') value = value.toString()
            if (value.toLocaleLowerCase().includes(filter)) {
                filtered.push(items[i1])
                break
            }
        }
    }
    return filtered
}

/**
 * Ritorna una lista di oggetti filtrata per valore.
 * Gli elementi filtrati hanno almeno un campo con un valore che include la stringa di ricerca.
 * La ricerca è case-insensitive
 * @param {Object[]} data lista di oggetti da filtrare
 * @param {String} filter stringa di ricerca
 * @return {Object[]}
 */
function listFilter(data: any[], filter: string = '', fields?: string[]) {
    if (!filter) return data
    const _filter = filter.toLowerCase()
    return data.filter((item: any) =>
        Object.entries(item).some(([k, v]: [string, any]) => {
            if (fields?.length && !fields.includes(k)) return false
            return v?.toString().toLowerCase().includes(_filter)
        }),
    )
}

/**
 * Ritorna una lista di oggetti ordinata tramite il metodo orderBy di Lodash
 * @param {Object[]} data lista di oggetti da ordinare
 * @param {String[]} sortData sequenza dei campi da ordinare
 * @param {('asc' | 'desc')[]} sortOrder sequenza degli ordninamenti da applicare a sortData
 * @return {Object[]}
 */
function listOrderBy<T>(data: T[], sortData: string[], sortOrder: ('asc' | 'desc')[]) {
    let sortedData: any = cloneDeep(data)
    sortedData.forEach((item: any) => {
        Object.entries(item).forEach(([k, v]) => {
            if (v === null) item[k] = ''
        })
    })
    sortedData = orderBy(sortedData, sortData, sortOrder)
    sortedData.forEach((item: any) => {
        Object.entries(item).forEach(([k, v]) => {
            if (v === '') item[k] = null
        })
    })
    return sortedData
}

/**
 * Interroga l'oggetto response.data di un json API e ritorna una strina del tipo:
 * codice - UP - ragione - sociale - denominazione aggiuntiva
 * @param {object} data response data
 * @param {boolean} [withCodice] se anteporre o meno il codice della ditta alla ragione sociale
 * @return {string}
 */
function ragSocialeFromData(data: { [key: string]: any }, withCodice = false): string {
    if (!data) return ''
    const items = []
    if (withCodice) {
        items.push(data.codiceDitta)
        if (data.unitaProduttiva) items.push(data.unitaProduttiva)
    }
    if (data.dittaUPRagioneSociale?.ragioneSociale) {
        items.push(data.dittaUPRagioneSociale.ragioneSociale)
    }
    if (data.dittaUPRagioneSociale?.denominazioneAggiuntiva) {
        items.push(data.dittaUPRagioneSociale.denominazioneAggiuntiva)
    }
    return items.join(' - ')
}

export function useUtils() {
    return {
        base64ToBlob,
        downloadFile,
        filterObjectList,
        listFilter,
        listOrderBy,
        ragSocialeFromData,
    }
}
