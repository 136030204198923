import { useGlobalStore } from '@/stores'
import minimaliMassimaliViews from '@/modules/paghe/views/tabelle/minimaliMassimali'

const tabPropsDefault: TabProps = {
    GROUP: 'MinimaleMassimaleCodice',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'Minimali/Massimali',
    CODVOCEMENU: '000031',
    CODPERMESSO: ['TABELLE_SP', 'TABELLE_STUDIO'], //DOVREBBE ESSERE UN ARRAY
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route) {
    return {
        ...route.params,
        year: Number(route.params?.year ?? useGlobalStore().state.periodoElab!.year),
        month: Number(route.params?.month ?? useGlobalStore().state.periodoElab!.month),
        attualeCheck: Number(route.params?.attualeCheck ?? 1),
        allValidate: Number(route.params?.allValidate ?? 0),
    }
}

export const SETTINGS = Object.freeze({
    DETTAGLIO: {
        TITLE: ' Tabelle di domino - Gestione - Minimali/Massimali',
        LABEL: 'Minimali/Massimali',
        PATH: 'minimaliMassimali/:year?/:month?/:attualeCheck?/:allValidate?/',
        NAME: 'DettagliominimaliMassimali',
        TAB: { ...tabPropsDefault },
    },
    MINIMALIMASSIMALI: {
        TITLE: 'Tabelle di domino - Gestione - Minimali/Massimali',
        LABEL: 'Minimali/Massimali',
        PATH: 'minimaliMassimali',
        NAME: 'minimaliMassimali',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            component: minimaliMassimaliViews.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                defaultProps: {
                    year: () => useGlobalStore().state.periodoElab!.year,
                    month: () => useGlobalStore().state.periodoElab!.month,
                    attualeCheck: 1,
                    allValidate: 0,
                },
            },
            props: propsFn,
            children: [
                {
                    path: SETTINGS.MINIMALIMASSIMALI.PATH,
                    name: SETTINGS.MINIMALIMASSIMALI.NAME,
                    component: minimaliMassimaliViews.childs.MinimaliMassimali,
                    meta: {
                        title: SETTINGS.MINIMALIMASSIMALI.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TMIMA',
                    },
                    props: propsFn,
                },
            ],
        },
    ],
}
