import { useRoute } from 'vue-router'
import { APIVQualificaDipte, pagheAPI } from '@/api'
import { Ref } from 'vue'

export function usePageDittaAlterazione() {
    const route = useRoute()

    // populate a map with codici alterazione based on a specified key (ex: $H)
    // codiciMap must be a Ref variable
    async function getCodiciAlterazione(key: string, codiciMap: Ref<Map<string, any>>) {
        // Rimuovere APIQualificaDipte e APiDittaGruppoAlterazione
        // Get Alterazioni gruppoAlterazione of specified ditta and key
        const alterazioni = await pagheAPI.anagrafiche.ditte.gruppoAlterazione.get({
            periodoDiRiferimento: {
                year: +route.params.year,
                month: +route.params.month,
            },
            filter: `[['idDitta','=','${Number(route.params.idPadre) > 0 ? route.params.idPadre : route.params.id}'], 'and', ['codice','startswith','${key}']]`,
            select: `['id','codice','descrizione']`,
        })
        const alterazioniData = alterazioni?.data?.data

        // Get qualifiche Dipte
        const qualifiche = await APIVQualificaDipte.get({
            periodoDiRiferimento: {
                year: +route.params.year,
                month: +route.params.month,
            },

            select: `['id','codice','descrizione']`,
        })

        const qualificheData = qualifiche.data.data

        // Merge the 2 array
        const data = [...qualificheData, ...alterazioniData]
        // Populate the gived map with the merged arrays
        data.forEach((item: any, index: any) => {
            const newItem = {
                codice: item.codice,
                descrizione: item.descrizione,
            }
            codiciMap.value.set(newItem.codice, newItem)
        })
    }

    // Restore map from store
    // Name is opzional (in case you want to use another name on the store)
    function restoreCodiciAlterazioni(
        codiciMap: Ref<Map<string, any>>,
        store: any,
        name: string = 'codiciAlterazioni',
    ) {
        ;[...store.state.dataChild.origin[name]].forEach(
            ([key, value]: [string, Map<string, any>]) => {
                codiciMap.value.set(key, value)
            },
        )
    }

    function sanitizeAlterazione(item: any): any {
        delete item.descrizione
        delete item.virtual_error
        delete item._rowIdx
        delete item.fromCall
        return item
    }

    return {
        getCodiciAlterazione,
        restoreCodiciAlterazioni,
        sanitizeAlterazione,
    }
}
