<template>
    <!-- APP-BAR -->
    <v-app-bar
        absolute
        class="app-bar"
        color="transparent"
        height="40"
        flat
    >
        <!-- WCAG: skip to content & altri link utili -->
        <!-- Bottone per INFO su comandi rapidi, visibile solo se focus-visible -->
        <div class="skippy visually-hidden-focusable overflow-hidden">
            <div class="container container-xl">
                <v-btn
                    rounded="pill"
                    @click="showInfoHotKeys = true"
                >
                    Vedi elenco comandi rapidi
                </v-btn>

                <v-btn
                    v-if="basePageBody"
                    rounded="pill"
                    @click="focusMeOnBody"
                >
                    Vai al contenuto principale
                </v-btn>
            </div>
        </div>

        <!-- Hamburger menu -->
        <HamburgerMenu :show-hmenu="true" />

        <!-- Logo e app -->
        <div>
            <div class="d-flex align-center">
                <img
                    alt="Logo Essepaghe menu modal window"
                    height="35"
                    src="@/assets/logo.png"
                    title="Logo Essepaghe menu modal window"
                    width="35"
                />
                <span
                    class="ml-1"
                    :style="{ letterSpacing: '1px', fontSize: '16px', fontWeight: 'bold' }"
                >
                    PAGHE
                </span>
            </div>
        </div>

        <!-- Tabs -->
        <div class="d-flex flex-nowrap align-items-end top-navigation-tabs">
            <v-tabs
                v-if="!globalStore.state.initApp"
                v-model="tabStore.state.activeTab"
                class="top-tabs"
                density="compact"
                hide-slider
                role="tablist"
            >
                <v-tab
                    v-for="(tab, key, idx) in tabStore.state.tabs"
                    :key="`${key}-${idx}`"
                    :aria-label="tab.TITLE"
                    :value="key"
                    class="mt-0"
                    :class="key === HomeRoutes.home.SETTINGS.HOME.TAB.GROUP && 'first-child'"
                    role="tab"
                    tabindex="0"
                    @click="goToTab(tab)"
                >
                    <v-icon
                        v-if="mdAndDown"
                        :class="tab.ICON"
                        size="small"
                    />
                    <span v-else>{{ tab.TITLE }}</span>
                    <v-tooltip
                        v-if="mdAndDown"
                        activator="parent"
                        location="bottom"
                        :text="tab.TITLE"
                    />
                    <v-icon
                        v-if="key !== HomeRoutes.home.SETTINGS.HOME.TAB.GROUP"
                        :aria-label="`Chiudi tab ${tab.TITLE}`"
                        class="ml-3 mr-2"
                        icon="fas fa-x"
                        size="x-small"
                        tabindex="0"
                        @click="event => closeTab(event, tab)"
                    />
                </v-tab>
            </v-tabs>
        </div>

        <v-spacer />

        <!-- Right side of the bar -->
        <div class="d-flex flex-nowrap pe-2 align-items-center">
            <div class="theme-mode-selection">
                <!-- operatore attivo -->
                <v-chip
                    v-if="permissionsStore.operatoreAttivoStr"
                    :color="darkTheme ? 'white' : 'black'"
                    class="mr-5 mx-auto"
                    label
                    variant="text"
                    :text="permissionsStore.operatoreAttivoStr"
                >
                    <template #prepend>
                        <v-icon
                            class="mr-3"
                            icon="far fa-link"
                            size="x-small"
                        />
                    </template>
                </v-chip>
            </div>
            <v-icon
                class="mr-3"
                icon="fal fa-pipe"
                size="large"
            />
            <!-- alert 'Schedulazione task' -->
            <div
                class="badge-number d-flex ml-1 mr-3"
                id="taskBadge"
                @click="taskCompletatiShow"
            >
                <v-icon
                    :class="{ 'icon-green': globalStore.state.taskCompletati.length }"
                    icon="fas fa-ballot-check"
                    size="x-small"
                />
                <v-badge
                    v-if="globalStore.state.taskCompletati.length"
                    :content="globalStore.state.taskCompletati.length"
                    offset-x="-6"
                    offset-y="-5"
                />
                <v-tooltip
                    activator="parent"
                    location="bottom"
                    text="Schedulazione attività"
                />
            </div>
            <!-- alert 'Nuovi Aggiornamenti' -->
            <div
                v-if="isAggTabDom"
                class="badge-number d-flex ml-1 mr-5"
                @click="aggTabDomShow"
            >
                <v-icon
                    class="icon-green"
                    icon="fas fa-cloud-arrow-down"
                    size="x-small"
                />
                <v-badge
                    v-if="aggTabDomTot"
                    :content="aggTabDomTot"
                    offset-x="-6"
                    offset-y="-5"
                />
                <v-tooltip
                    activator="parent"
                    location="bottom"
                    text="Aggiornamenti tabelle dominio"
                />
            </div>
            <!-- menu 'Altre operazioni' -->
            <v-btn
                aria-label="Altre operazioni"
                :color="darkTheme ? 'white' : 'black'"
                icon
                density="compact"
            >
                <v-icon
                    icon="fas fa-ellipsis-vertical"
                    size="x-small"
                />
                <v-tooltip
                    activator="parent"
                    location="bottom"
                    text="Altre operazioni"
                />
                <v-menu activator="parent">
                    <v-list
                        :bg-color="darkTheme ? 'black' : 'white'"
                        density="compact"
                    >
                        <div
                            v-for="(ope, idx) in menuOperazioni"
                            :key="idx"
                        >
                            <v-divider
                                v-if="ope.divider"
                                class="my-0"
                            />
                            <v-list-item
                                :disabled="ope.disabled"
                                :prepend-icon="`far fa-${ope.icon}`"
                                :style="{ borderBottom: 'none' }"
                                :title="typeof ope.title === 'function' ? ope.title() : ope.title"
                                @click="ope.action?.() || (() => {})"
                            >
                                <template #append>
                                    <v-icon
                                        v-if="ope.appendIcon"
                                        :icon="`far fa-${ope.appendIcon.icon}`"
                                        :color="ope.appendIcon?.color || ''"
                                        @click="event => ope.appendIcon?.action?.(event)"
                                    />
                                </template>
                            </v-list-item>
                        </div>
                    </v-list>
                </v-menu>
            </v-btn>
            <!-- menu utente -->
            <v-btn
                aria-label="Gestione Utente"
                :color="darkTheme ? 'white' : 'black'"
                icon
                density="compact"
            >
                <v-icon
                    class="fas fa-circle-user"
                    size="x-small"
                />
                <v-tooltip
                    activator="parent"
                    location="bottom"
                    text="Gestione Utente"
                />
                <v-menu activator="parent">
                    <v-list
                        :bg-color="darkTheme ? 'black' : 'white'"
                        density="compact"
                    >
                        <v-list-item
                            prepend-icon="fas fa-user"
                            :title="userProfile.name"
                            @click="goToProfile"
                        />
                        <v-list-item
                            prepend-icon="fas fa-arrow-right-from-bracket"
                            title="Logout"
                            @click="doLogout"
                        />
                    </v-list>
                </v-menu>
            </v-btn>
        </div>
    </v-app-bar>

    <!-- CONTENT -->
    <v-container
        class="pa-0"
        fluid
    >
        <!-- Router view -->
        <div class="main-content-wrapper">
            <v-main class="ms-0 me-0 ms-xl-3 me-xl-3">
                <v-container class="page__container mt-0 main-box-shadow position-relative">
                    <RouterView
                        v-if="routerViewOn"
                        ref="child"
                    />
                </v-container>
            </v-main>
        </div>

        <!-- Info Keyboard shortcut -->
        <InfoHotKeys
            :show="showInfoHotKeys"
            @update="(show: boolean) => (showInfoHotKeys = show)"
        />
    </v-container>

    <BaseLoader
        :show="!!globalStore.state.loading"
        :text="globalStore.state.loadingText || ''"
    />

    <!-- PROMISE DIALOG PLUGIN -->
    <PromiseDialogsWrapper />
</template>

<script setup lang="ts">
import type { Ref, ShallowReactive } from 'vue'
import {
    computed,
    defineAsyncComponent,
    inject,
    nextTick,
    onBeforeMount,
    onMounted,
    onUnmounted,
    ref,
    shallowReactive,
    watchPostEffect,
} from 'vue'
import { Emitter } from 'mitt'
import { createPromiseDialog, PromiseDialogsWrapper } from 'vue-promise-dialogs'
import { upperFirst } from 'lodash'
import { useRoute, useRouter } from 'vue-router'
import { useDisplay, useTheme } from 'vuetify'
import Swal from 'sweetalert2'

import type { LinkHelpProps } from '@/components/LinkHelp.vue'
import HomeRoutes from '@paghe/routes/home'
import { services, APIAggiornamenti } from '@/api'
import { MessageBox } from '@/scripts/services/utility'
import { useGlobalStore, useTabStore } from '@/stores'
import { useStoreBeMessage } from '@/scripts/stores/utility/beMessage/beMessage'
import { useStorePermissions } from '@/scripts/stores/Permissions/pagePermission'
import AuthService from '@/typescript/Service/auth-service'
import BaseLoader from '@/components/base/BaseLoader.vue'
import HamburgerMenu from '@/components/navigation/menu/HamburgerMenu.vue'
import InfoHotKeys from '@/components/InfoHotKeys.vue'
import LinkHelp, { WIKI_PREFIX } from '@/components/LinkHelp.vue'
import Scheduler from '@/typescript/Service/scheduler'

//#region DECLARATIONS
const globalStore = useGlobalStore()
const permissionsStore = useStorePermissions()
const router = useRouter()
const tabStore = useTabStore()

const { mdAndDown } = useDisplay()

const theme = useTheme()

const aggTabDomTot: Ref<number> = ref(0)
const aggTabDomCheckFn = setInterval(aggTabDomCheck, 7200000) // 2 ore
const scheduler: Ref<Scheduler | undefined> = ref()
const emitter = inject('emitter') as Emitter<{ CtrlI: string }>
const basePageBody: Ref<HTMLElement | null> = ref(null)
const routerViewOn: Ref<boolean> = ref(false)
const showInfoHotKeys: Ref<boolean> = ref(false)
let userProfile: ShallowReactive<any> = shallowReactive({})

const tooltipIdPaginaMsg = computed(() => {
    // Usa il valore di props.idPaginaMsg se è presente e non vuoto, altrimenti fallback sul valore dalla route
    return useRoute().meta?.idPaginaMsg as string
})

const darkTheme = computed({
    get(): boolean {
        return globalStore.state.darkTheme
    },
    set(value: boolean): void {
        globalStore.state.darkTheme = value
    },
})
switchTheme(darkTheme.value)

const helpIdPagina = computed(() =>
    [
        'Paghe',
        upperFirst(routeMeta.value.tabGroup as string),
        upperFirst(router.currentRoute.value.name as string),
    ].join('_'),
)

const isAggTabDom = computed(() => {
    return permissionsStore.pagePermissions.some(
        (permission: any) =>
            permission.codPermesso === 'AGG_TAB_DOMINIO' &&
            (permission.codTipoPermesso === 'A' || permission.codTipoPermesso === 'C'),
    )
})

const isHelpGest = computed(() => {
    return permissionsStore.pagePermissions.some(
        (permission: any) =>
            permission.codPermesso === 'HELP_GEST' && permission.codTipoPermesso === 'A',
    )
})

const menuOperazioni = computed(() => [
    { disabled: true, icon: 'at', title: 'Email' },
    {
        action: helpOpen,
        appendIcon: {
            action: (event: Event) => {
                event.stopPropagation()
                helpCopy()
            },
            icon: 'copy',
            title: 'Copia ID Help pagina corrente',
        },
        divider: true,
        icon: 'circle-question',
        title: idPaginaMsg,
    },
    {
        action: helpLink,
        disabled: !isHelpGest.value,
        icon: 'cloud-question',
        title: 'Gestione Help',
    },
    { disabled: true, divider: true, icon: 'memo-circle-info', title: 'Note di aggiornamento' },
    { disabled: true, icon: 'message-lines', title: 'Comunicazioni' },
    { disabled: true, icon: 'link', title: 'Links utili' },
    {
        action: () => switchTheme(!darkTheme.value),
        appendIcon: {
            color: darkTheme.value ? 'yellow' : 'black',
            icon: darkTheme.value ? 'sun' : 'moon',
        },
        divider: true,
        icon: 'palette',
        title: `Passa a tema ${darkTheme.value ? 'CHIARO' : 'SCURO'}`,
    },
    { disabled: true, icon: 'gear', title: 'Impostazioni' },
])

const routeMeta = computed(() => router.currentRoute.value.meta)
//#endregion

//#region HOOKS
onBeforeMount(async () => {
    if (
        !permissionsStore.operatori.find((operatore: Operatore) => operatore.attivo) &&
        !(await permissionsStore.getProfile())
    ) {
        doLogout()
        return
    }
    routerViewOn.value = false
    globalStore.loadingSet()
    await AuthService.getInstance()
        .getUser()
        .then(user => (userProfile = user?.profile || {}))
    await globalStore.init()
    await Promise.all([useStoreBeMessage().fetchBeMessageAll()])
    globalStore.state.initApp = false
    aggTabDomCheck()
    scheduler.value = new Scheduler()
    scheduler.value.start()
    routerViewOn.value = true
    globalStore.loadingUnset()
})

onMounted(async () => {
    emitter.on('CtrlI', openInfoHotKeys)
})

onUnmounted(() => {
    emitter.off('CtrlI', openInfoHotKeys)
    clearInterval(aggTabDomCheckFn)
})
//#endregion

//#region WATCHING
watchPostEffect(() => {
    document.title = (routeMeta.value.title as string) || 'EssePaghe - Gestione Paghe'
    nextTick(() => {
        basePageBody.value = document.querySelector('.base-page-body')
    })
})
//#endregion

//#region FUNCTIONS
async function closeTab(event: Event, tab: TabProps) {
    if (tabStore.state.activeTab === tab.GROUP) {
        tabStore.state.routeRedirect = null
        event.stopPropagation()
        await tabStore.closeTab(tab.GROUP)
    } else {
        tabStore.state.routeRedirect = tabStore.state.activeTab
        if (tabStore.state.tabs[tab.GROUP].toSave) {
            tabStore.state.tabs[tab.GROUP].toSave = 2
        } else {
            event.stopPropagation()
            await tabStore.closeTab(tab.GROUP)
        }
    }
}

function doLogout() {
    scheduler.value?.stop()
    AuthService.getInstance().logout()
}

function focusMeOnBody() {
    basePageBody.value?.focus() // WCAG
}

function goToProfile() {
    window
        .open('https://openidserverdev.azurewebsites.net/Identity/Account/Manage', '_blank')
        ?.focus()
}

function goToTab(tab: CurrentTab) {
    if (tab.GROUP === routeMeta.value.tabGroup) return
    //routerViewOn.value = false
    //nextTick(() => {
    router.replace({ name: tab.currentTabRoute.name, params: { ...tab.currentTabRoute.props } })
    //    routerViewOn.value = true
    //})
}

async function helpCopy() {
    navigator.clipboard.writeText(helpIdPagina.value)
    Swal.fire({
        customClass: { popup: 'colored-toast' },
        html: 'ID Help pagina copiato',
        icon: 'info',
        iconColor: 'white',
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        toast: true,
    })
}

async function helpLink() {
    await createPromiseDialog<LinkHelpProps, boolean>(LinkHelp)({
        helpIdPagina: helpIdPagina.value,
    })
}

async function helpOpen() {
    const response = await services.APIHelp.get({ idPaginaList: [helpIdPagina.value] })
    const { responseStatus, payload } = response?.data
    if (responseStatus.isSuccessful) {
        const urlSuffix = payload.raccordoPaginaHelpList[0]?.linkHelp
        if (urlSuffix) window.open(WIKI_PREFIX + urlSuffix, 'wikiHelp')
    }
}

function openInfoHotKeys() {
    showInfoHotKeys.value = true // WCAG
}

async function aggTabDomCheck() {
    if (!isAggTabDom.value) return
    const response = await APIAggiornamenti.get()
    const { responseStatus, payload } = response?.data
    if (responseStatus.isSuccessful) aggTabDomTot.value = payload.numeroAggiornamenti || 0
}

function aggTabDomShow() {
    let msg = aggTabDomTot.value ? `Ci sono ${aggTabDomTot.value}` : 'Non ci sono'
    msg += ' aggiornamenti da scaricare.'
    MessageBox.infoPopupMessage(msg)
}

function switchTheme(isDarkTheme: boolean) {
    theme.global.name.value = isDarkTheme ? 'dark' : 'lightTheme'
    darkTheme.value = isDarkTheme
    const func = isDarkTheme ? 'add' : 'remove'
    document.body.classList[func]('dark-theme')
    document.getElementsByTagName('html')[0].classList[func]('dark-theme')
}

async function taskCompletatiShow() {
    const Attivita = defineAsyncComponent(() => import('@paghe/modals/schedulazioni/Attivita.vue'))
    await createPromiseDialog<undefined, boolean>(Attivita)(undefined)
    globalStore.state.taskCompletati = []
}

function idPaginaMsg() {
    const router = useRouter()
    if (router.currentRoute.value.params?.codTable != undefined) {
        return `Help in linea${router.currentRoute.value.params?.codTable ? ` ( ${router.currentRoute.value.params?.codTable} )` : ''}`
    }

    return `Help in linea${useRoute().meta?.idPaginaMsg ? ` ( ${useRoute().meta.idPaginaMsg} )` : ''}`
}
//#endregion
</script>

<style lang="scss">
.badge-number {
    cursor: pointer;
    .icon-green {
        color: var(--color-icon-green);
    }
    .v-badge__badge {
        font-size: 0.7rem;
        height: 1rem;
        min-width: 1rem;
    }
}
</style>

<style lang="scss">
@import '@/styles/global/vars';
@media screen and (min-width: 800px) {
    .theme-mode-selection {
        .v-btn__content {
            display: block;
        }
    }
}

@media (min-width: 1024px) and (orientation: landscape) {
    .v-tabs.top-tabs {
        padding-left: 50px !important;

        .v-tab {
            padding-left: 1rem;
            min-width: 90px;
        }
    }
}

.app-bar {
    .v-toolbar__content {
        background: var(--bg-toolbar-content) !important;
        border-bottom: 2.5px solid var(--top-tabs-border-color);
    }
    .top-navigation-tabs {
        height: $headHeight;
        margin-bottom: 2px;
        .v-tabs--density-compact {
            --v-tabs-height: 32px !important;
        }
        .v-tabs.top-tabs {
            background: none;
            line-height: 12px;

            .v-tabs-bar {
                background: none !important;
                height: 26px;
            }
            .v-tabs-slider-wrapper {
                color: var(--bg-v-tab-active);
            }
            .v-tab {
                background: var(--bg-v-tab);
                border-radius: 4px 4px 0 0 !important;
                color: #fff;
                font: normal normal 600 14px/19px Segoe UI;
                letter-spacing: 0;
                margin-left: 0.5rem;
                margin-top: 4px;
                padding: 0px;
                padding-left: 1rem;
                &.first-child {
                    padding-left: 0px;
                }
                .v-btn {
                    color: #fff !important;
                }
                &--active,
                &--selected {
                    background: var(--bg-v-tab-active);
                    color: #fff;
                    margin-top: 0px;
                }
                &:focus-within {
                    background: var(--bg-v-tab);
                    margin-top: 0px;
                }
            }
            .v-tab--selected {
                &:focus-within {
                    background: var(--bg-v-tab-active);
                    margin-top: 0px;
                }
            }
            .v-tab--active {
                &:focus {
                    background: var(--bg-v-tab);
                    margin-top: 0px;
                }
            }
        }
    }
}

.main-content-wrapper {
    background-color: var(--bg-main-content-wrapper) !important;
    border-color: #eee !important;
    .v-main {
        padding-top: 0 !important;
        margin-top: #{$headHeight} !important;
        height: calc(100vh - #{$headHeight});
        .main-box-shadow {
            box-shadow: 0 3px 6px var(--box-shadow-color);
        }
        .page__container {
            //larghezza per le pagina con la sidebar
            height: calc(100% - $footerHeight);
            width: 100%;
            max-width: unset !important;
            margin: 0 0 0 0 !important;
            padding: 0;
            background-color: var(--bg-page-container);
            overflow-y: auto;
            overflow-x: hidden;
            .v-slide-group {
                &__content {
                    border-bottom: 1px solid $gray7;
                }
            }
        }
    }
}
</style>
