import { useGlobalStore } from '@/stores'
import aliquoteViews from '@paghe/views/tabelle/aliquote'

const tabPropsDefault: TabProps = {
    GROUP: 'tabelleAliquote',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'Aliquote',
    CODVOCEMENU: '000022',
    CODPERMESSO: ['TABELLE_SP', 'TABELLE_STUDIO'], //DOVREBBE ESSERE UN ARRAY
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route) {
    return {
        ...route.params,
        year: Number(route.params?.year ?? useGlobalStore().state.periodoElab!.year),
        month: Number(route.params?.month ?? useGlobalStore().state.periodoElab!.month),
        attualeCheck: Number(route.params?.attualeCheck ?? 1),
        allValidate: Number(route.params?.allValidate ?? 0),
        codice: route.params?.codice ?? '0',
    }
}

export const SETTINGS = Object.freeze({
    DETTAGLIO: {
        TITLE: ' Tabelle di domino - Gestione - Aliquote',
        LABEL: 'Aliquote',
        PATH: 'tabelleAliquote/:year?/:month?/:attualeCheck?/:allValidate?/:codice?/',
        NAME: 'DettaglioAliquote',
        TAB: { ...tabPropsDefault },
    },
    ALIQUOTE: {
        TITLE: 'Tabelle di domino - Gestione - Aliquote',
        LABEL: 'Aliquote',
        PATH: 'aliquote',
        NAME: 'Aliquote',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            component: aliquoteViews.dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                defaultProps: {
                    year: () => useGlobalStore().state.periodoElab!.year,
                    month: () => useGlobalStore().state.periodoElab!.month,
                    attualeCheck: 1,
                    allValidate: 0,
                    codice: '0', //elemento neutro
                },
            },
            props: propsFn,
            children: [
                {
                    path: SETTINGS.ALIQUOTE.PATH,
                    name: SETTINGS.ALIQUOTE.NAME,
                    component: aliquoteViews.childs.aliquote,
                    meta: {
                        title: SETTINGS.ALIQUOTE.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TALIQ',
                        defaultProps: {
                            //year: () => useGlobalStore().state.periodoElab.year,
                            //month: () => useGlobalStore().state.periodoElab.month,
                            //attualeCheck: 1,
                            //allValidate: 0,
                        },
                    },
                    props: propsFn,
                },
            ],
        },
    ],
}
