const Generale = () => import('./Generale.vue')
const Imponibile = () => import('./ImponibileContributi.vue')
const Settimana = () => import('./SettimanaDiffAcredito.vue')
const InfoAggCausaliContribEventiCig = () => import('./InfoAggCausaliContribEventiCig.vue')
const PreavvisoAppaltiBonusEstero = () => import('./PreavvisoAppaltiBonusEstero.vue')
const ContribSindContrAtipica = () => import('./ContribSindContrAtipica.vue')
const LavDomicilio = () => import('./LavDomicilio.vue')
const FondiSpeciali = () => import('./FondiSpeciali.vue')
const TfrMeseTfrDestinazione = () => import('./TfrMeseTfrDestinazione.vue')
const SportSpettacolo = () => import('./SportSpettacolo.vue')

export {
    Generale,
    Imponibile,
    Settimana,
    InfoAggCausaliContribEventiCig,
    PreavvisoAppaltiBonusEstero,
    ContribSindContrAtipica,
    LavDomicilio,
    FondiSpeciali,
    TfrMeseTfrDestinazione,
    SportSpettacolo,
}
