import { call } from '@/scripts/lib/http'
import type { AxiosResponse } from 'axios'

const BASE_URL = '/Contratto'

class API {
    @call({ url: `${BASE_URL}/GetContratti` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<Contratto>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveContrattoGenerale` })
    static async saveContrattoGenerale(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveContrattoGenerale>> | null> {
        return null
    }
}

export default {
    get: API.get,
    saveContrattoGenerale: API.saveContrattoGenerale,
}
