import { AxiosResponse } from 'axios'

import { call } from '@/scripts/lib/http'

const BASE_URL = '/ArchivioFile'

const defaultPayload: Payload = {
    isNuovaValidita: true,
}

class API {
    @call({ url: `${BASE_URL}/SaveArchivioFile`, defaultPayload })
    static async savePdfFile<P>(payload?: P): Promise<AxiosResponse<BasicResponsePayload> | null> {
        return null
    }
}

export default {
    savePdfFile: API.savePdfFile<SaveReportPayload>,
}
