import { useGlobalStore } from '@/stores'
import sindacatoViews from '@paghe/views/tabelle/sindacato'

const tabPropsDefault: TabProps = {
    GROUP: 'SindacatoCodice',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'Sindacato',
    CODVOCEMENU: '000036',
    CODPERMESSO: ['TABELLE_SP', 'TABELLE_STUDIO'], //DOVREBBE ESSERE UN ARRAY
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route) {
    return {
        ...route.params,
        year: Number(route.params?.year ?? useGlobalStore().state.periodoElab!.year),
        month: Number(route.params?.month ?? useGlobalStore().state.periodoElab!.month),
        attualeCheck: Number(route.params?.attualeCheck ?? 1),
        allValidate: Number(route.params?.allValidate ?? 0),
    }
}

export const SETTINGS = Object.freeze({
    DETTAGLIO: {
        TITLE: ' Tabelle di domino - Gestione - Sindacati-Finanze-Creditori',
        LABEL: 'Sindacato',
        PATH: 'tabelleSindacato/:year?/:month?/:attualeCheck?/:allValidate?/',
        NAME: 'DettaglioSindacato',
        TAB: { ...tabPropsDefault },
    },
    SINDACATO: {
        TITLE: 'Tabelle di domino - Gestione - Sindacati-Finanze-Creditori',
        LABEL: 'Sindacato',
        PATH: 'sindacato',
        NAME: 'Sindacato',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            component: sindacatoViews.dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                defaultProps: {
                    year: () => useGlobalStore().state.periodoElab!.year,
                    month: () => useGlobalStore().state.periodoElab!.month,
                    attualeCheck: 1,
                    allValidate: 0,
                },
            },
            props: propsFn,
            children: [
                {
                    path: SETTINGS.SINDACATO.PATH,
                    name: SETTINGS.SINDACATO.NAME,
                    component: sindacatoViews.childs.sindacato,
                    meta: {
                        title: SETTINGS.SINDACATO.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TSIND',
                    },
                    props: propsFn,
                },
            ],
        },
    ],
}
