import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/VMessaggio'

class API {
    @call({ url: `${BASE_URL}/GetVMessaggi` })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<VMessaggio>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveVMessaggio` })
    static async save<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveVMessaggioResponse>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
