import aliquote from './aliquote'
import cassaEdile from './cassaEdile'
import causaliEvento from './causaliEvento'
import codiciDiario from './codici-diario'
import contratto from './contratto'
import formule from './formule'
import gruppiRisultati from './gruppi-risultati'
import minimaliMassimali from './minimaliMassimali'
import prospettoContabileCod from './prospettoContabileCod'
import prospettoContabileRiga from './prospettoContabileRiga'
import retribuzione from './retribuzione'
import scadenze from './scadenze'
import sgravi from './sgravi'
import sindacato from './sindacato'
import varie from './varie'
import voci from './voci'

export default {
    aliquote,
    cassaEdile,
    causaliEvento,
    codiciDiario,
    contratto,
    formule,
    gruppiRisultati,
    minimaliMassimali,
    prospettoContabileCod,
    prospettoContabileRiga,
    retribuzione,
    scadenze,
    sgravi,
    sindacato,
    varie,
    voci,
}
