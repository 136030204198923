import { useGlobalStore } from '@/stores'
import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    GROUP: 'uniemens',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'Uniemens SPETT',
    CODPERMESSO: 'RII_DITTA', //TODO: DA CAMBIARE CHIEDERE A Davide
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route) {
    const params = {
        year: Number(route.params.year),
        month: Number(route.params.month),
        day: Number(route.params.day),
        id: Number(route.params.id),
        idPadre: Number(route.params.idPadre),
        isSp: String(route.params.isSp) === '1',
    }

    params.isSp = true

    return params
}

export const SETTINGS = Object.freeze({
    DITTA_DETTAGLIO: {
        TITLE: 'Dettaglio uniemens Ditta - uniemens - Paghe',
        LABEL: 'Dettaglio ditta uniemens',
        PATH: 'uniemensSpettacoloDitta/:year/:month/:day/:id/:idPadre/:isSp?',
        NAME: 'uniemensSpettacoloDittaDettaglio',
        TAB: { ...tabPropsDefault },
    },
    GENERALE: {
        TITLE: '',
        LABEL: 'Generale',
        PATH: 'generale',
        NAME: 'uniemensSpettacoloGeneraleDitta',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DITTA_DETTAGLIO.PATH,
            name: SETTINGS.DITTA_DETTAGLIO.NAME,
            props: propsFn,
            component: pagheViews.uniemens.ditta.dettaglio.Dettaglio,
            meta: {
                title: SETTINGS.DITTA_DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    label: SETTINGS.GENERALE.LABEL,
                    path: SETTINGS.GENERALE.PATH,
                    name: SETTINGS.GENERALE.NAME,
                    tab: SETTINGS.GENERALE.TAB,
                    component: pagheViews.uniemens.ditta.dettaglio.childs.Generale,
                    meta: {
                        group: SETTINGS.DITTA_DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UND01S',
                    },
                    orderWizard: 0,
                    props: propsFn,
                },
            ],
        },
    ],
}
