import schedulazioni from './schedulazioni'
import utility from './utility'
import importExportJSON from './import-export-json'

const mapMenuModals = new Map([
    ['000191', utility.duplica.ditta.Duplica],
    ['000192', utility.duplica.lavoratore.Duplica],
    ['000193', utility.elimina.ditta.Elimina],
    ['000194', utility.elimina.lavoratore.Elimina],
    ['000198', importExportJSON.Export],
    ['000133', importExportJSON.Import],
])

export { importExportJSON, mapMenuModals, schedulazioni, utility }
