// moduli
import pagheRoutes, { routes as RoutesPagheModule } from '@paghe/routes'

import RoutesAdmin from './admin'
import RoutesPaghe from './route-paghe'

export const ROUTES_INDEX = Object.freeze({
    INDEX_PAGHE: {
        TITLE: null,
        LABEL: null,
        PATH: '/paghe',
        NAME: null,
    },
})

export const routes = [
    {
        path: '/',
        name: 'root',
    },
    {
        path: ROUTES_INDEX.INDEX_PAGHE.PATH,
        children: [...RoutesAdmin, ...RoutesPaghe, ...RoutesPagheModule],
        name: 'paghe',
    },
]

export { pagheRoutes }
