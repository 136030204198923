import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/ElabDitta'

class API {
    @call({ url: `${BASE_URL}/GetRisultatiElaborazioneDitta` })
    static async getRisultati(
        payload: GetRisultatiElaborazioneDitta,
    ): Promise<AxiosResponse<BasicResponsePayload<ElaborazioniDittaResponse>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveRisultatiElaborazioneDitta` })
    static async saveRisultati(
        payload: SaveDittaElaborazionePayload,
    ): Promise<AxiosResponse<BasicResponsePayload<ElaborazioniDittaResponse>> | null> {
        return null
    }
}

export default {
    getRisultati: API.getRisultati,
    saveRisultati: API.saveRisultati,
}
