import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/ElabDittaUE'

class API {
    @call({ url: `${BASE_URL}/GetElabDittaUE` })
    static async getElabDittaUE(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponsePayload<ElabDittaUE>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveDittaElabUE` })
    static async saveDittaElabUE(
        payload: SaveDittaElabUE,
    ): Promise<AxiosResponse<BasicResponsePayload<ElabDittaUE>> | null> {
        return null
    }
}

export default {
    getElabDittaUE: API.getElabDittaUE,
    saveDittaElabUE: API.saveDittaElabUE,
}
