import ditta from './ditta'
import dittaSpettacolo from './dittaSpettacolo'
import dipendente from './dipendente'
import dipendenteCig from './dipendenteCig'
import collaboratore from './collaboratore'
import collaboratoreSpettacolo from './collaboratoreSpettacolo'

export default {
    collaboratore,
    collaboratoreSpettacolo,
    ditta,
    dittaSpettacolo,
    dipendente,
    dipendenteCig,
}
