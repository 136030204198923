import { useGlobalStore } from '@/stores'
import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    GROUP: 'mensilita',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'mens.agg',
    CODVOCEMENU: '000009',
    CODPERMESSO: 'MOVIMENTO', //TODO: DA CAMBIARE CHIEDERE A Davide
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route) {
    return {
        year: Number(route.params.year),
        month: Number(route.params.month),
        day: Number(route.params.day),
        id: Number(route.params.id),
        idPadre: Number(route.params.idPadre),
        isSp: String(route.params.isSp) === '1',
    }
}

export const SETTINGS = Object.freeze({
    DITTE_LISTA: {
        TITLE: 'Elenco Ditte - Mensilita - Gestione - Paghe',
        LABEL: 'Elenco Ditte Mensilita',
        PATH: 'mensilitaDitteList',
        NAME: 'ElencoDitteMensilita',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DITTE_LISTA.PATH,
            name: SETTINGS.DITTE_LISTA.NAME,
            component: pagheViews.mensilita.Elenco,
            meta: {
                title: SETTINGS.DITTE_LISTA.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            props: true,
        },
    ],
}
