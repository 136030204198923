import { APIBanca, APIEnte } from '@/api'
import { pagheAPI } from '@/api'
import { groupBy, mapValues, map } from 'lodash'

const STORE_ATTRS = {
    DATA_ROOT: 'data',
    KEY: 'id',
}

export default {
    DB_NAME: 'Tabelle',
    STORES: {
        BANCHE: {
            ...STORE_ATTRS,
            API: () => APIBanca,
            PARAMS: {},
            STORE_NAME: 'Banche',
        },
        COMUNI: {
            ...STORE_ATTRS,
            API: () => pagheAPI.tabelle.comune,
            KEY: 'codice',
            PARAMS: {
                include:
                    "['comuneDato','comuneDato.comuneDatoAddizionaleList','comuneNazioneList']",
            },
            STORE_NAME: 'Comuni',
            VALIDITY: true,
            __MODULES: true, // temporaneo fino a passaggio di tutte le altre
            CALLBACK: (data: Comune[]) => {
                const comuniGroup = groupBy(data, 'codice')
                const finalData: Comune[] = []

                Object.keys(comuniGroup).forEach(key => {
                    const comune = mapComuneToIndexDB(comuniGroup[key])
                    finalData.push(comune)
                    return
                })

                return finalData
            },
        },
        ENTI: {
            ...STORE_ATTRS,
            API: () => APIEnte,
            PARAMS: {
                include:
                    "['enteScaglione', 'tfrFpcTipo','enteScaglioneList','enteAltraProprietaList','enteQualificaList']",
            },
            STORE_NAME: 'Enti',
        },
        VCODDES: {
            ...STORE_ATTRS,
            API: () => pagheAPI.tabelle.vCodDes,
            KEY: 'funzione',
            PARAMS: { include: "['vcodDesElementoList']" },
            STORE_NAME: 'VCodDes',
            VALIDITY: true,
            __MODULES: true, // temporaneo fino a passaggio di tutte le altre
        },
    },
}

/**
 * Dato un array di comuni raggruppati per codice (quindi stesso comune ma con childs diverse tipo comuneDato) restituisce il singolo comune con le medesime proprieta di quello base
 * ma con eventuali proprieta inserite in un array. Es. comuneDato potrebbe differire per il tipo Comune dunque all'interno dell'oggetto restituito la prop. comuneDato sara' un array di tipo ComuneDato
 * @param comuniGroup array di oggetti Comune raggruppati per un dato codice.
 * @param keyToMerge nomi delle prop di Comune pe le quali si vuole che sia effettuato il merge in un array
 * @returns
 */
export function mapComuneToIndexDB(comuniGroup: Comune[], keyToMerge: string[] = ['comuneDato']) {
    const comune = mapValues(comuniGroup[0], (value, chiave) => {
        if (comuniGroup.length > 1 && keyToMerge.includes(chiave)) return map(comuniGroup, chiave)
        return value
    })
    return comune
}
