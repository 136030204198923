import HomeViews from '@paghe/views/home'

const tabPropsDefault: TabProps = {
    GROUP: 'home',
    ICON: 'fa-regular fa-house-user',
    TITLE: 'Home',
    CODVOCEMENU: '000000',
    CODPERMESSO: 'ALL',
}

export const SETTINGS = {
    HOME: {
        TITLE: 'Bacheca - Paghe',
        LABEL: null,
        PATH: 'home',
        NAME: 'Home',
        TAB: { ...tabPropsDefault },
    },
} as const

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.HOME.PATH,
            name: SETTINGS.HOME.NAME,
            component: HomeViews.Home,
            meta: {
                title: SETTINGS.HOME.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                metaTags: [
                    {
                        name: 'description',
                        content: "Gestione Paghe, home page dell'applicazione",
                    },
                    {
                        property: 'og:description',
                        content: "Gestione Paghe, home page dell'applicazione",
                    },
                ],
                tabGroup: tabPropsDefault.GROUP,
            },
        },
    ],
}
