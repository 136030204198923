import { createPromiseDialog } from 'vue-promise-dialogs'
import DiarioModal from '@/components/DiarioModal.vue'
import { toValue } from 'vue'

export function useDiario(refDiario: any = null) {
    //gestione aggiunta diario al payload
    const addDiarioToSavePayload = (payload: any, idDipte: any, idDitta: any) => {
        if (!idDipte && !idDitta) return payload

        let storedSessionDiarioLogResult = null
        const storedSessionDiarioLog = sessionStorage.getItem('diarioLog')
        storedSessionDiarioLogResult = JSON.parse(storedSessionDiarioLog)
        if (storedSessionDiarioLogResult != null) {
            if (idDipte != null) {
                storedSessionDiarioLogResult = {
                    ...storedSessionDiarioLogResult,
                    ...{ idDipte: idDipte },
                }
            }
            if (idDitta != null) {
                storedSessionDiarioLogResult = {
                    ...storedSessionDiarioLogResult,
                    ...{ idDitta: idDitta },
                }
            }
            payload = addDiarioToPayload(payload, storedSessionDiarioLogResult)
            sessionStorage.removeItem('diarioLog')
        }
        return payload
    }

    //gestione aperturaDiario
    const openDiario = async (): Promise<IDiarioModalResult | undefined> => {
        if (refDiario) {
            return (await createPromiseDialog(DiarioModal)(
                toValue(refDiario),
            )) as IDiarioModalResult
        } else {
            return { status: false, diarioLog: null }
        }
    }

    //aggiunta entita Diario al payload in ingresso
    const addDiarioToPayload = (inputPayload: any, diarioLog: any): any => {
        inputPayload = {
            ...inputPayload,
            ...{ diarioLog: diarioLog },
        }

        return inputPayload
    }

    const openDiarioDipte = async (extraCondition: boolean = true) => {
        if (extraCondition) {
            //solo in modalita consultazione
            //il payload da aggiungere viene salvato nello store cosi da non doverlo passare nelle varie funzioni
            const diarioResponse = await openDiario()
            if (!diarioResponse.status) {
                return false
            }
        }
        return true
    }

    return {
        openDiario,
        addDiarioToPayload,
        openDiarioDipte,
        addDiarioToSavePayload,
    }
}
