import { SommDistList } from '@/views/Paghe/Anagrafiche/Sommdist/Lista'
import { SommDistDettaglio, Childs } from '@/views/Paghe/Anagrafiche/Sommdist/Dettaglio'

const tabPropsDefault: TabProps = {
    TITLE: 'Anagrafica somministrati / distaccati',
    ICON: 'fa-regular fa-users-between-lines',
    GROUP: 'anagraficaSommDist',
}

//https://router.vuejs.org/guide/essentials/passing-props

function propsFn(route: any) {
    return {
        ...route.params,
        id: Number(route.params.id),
        day: Number(route.params.day),
        dittaId: Number(route.params.dittaId),
        dittaPadreId: Number(route.params.dittaPadreId),
        month: Number(route.params.month),
        year: Number(route.params.year),
    }
}

export const ROUTES_SOMMDIST = Object.freeze({
    SOMMLIST_LISTA: {
        TITLE: 'Elenco Somministrati & Distaccati - Somministrati & Distaccati - Anagrafici - Gestione',
        LABEL: 'Elenco Somministrati & Distaccati',
        PATH: 'sommdist/:year/:month/:dittaId/:dittaPadreId',
        NAME: 'SommDistList',
        TAB: { ...tabPropsDefault },
    },
    SOMMLIST_DETTAGLIO: {
        TITLE: 'Dettaglio Somministrati & Distaccati - Somministrati & Distaccati - Anagrafici - Gestione - Paghe',
        LABEL: 'Dettaglio Somministrati & Distaccati',
        PATH: 'sommdist/:year/:month/:day/:dittaId/:dittaPadreId/:id/:mod',
        NAME: 'SommDistDettaglio',
        TAB: { ...tabPropsDefault },
    },
    SOMMLIST_ANAGRAFICA: {
        TITLE: null,
        LABEL: 'Dati anagrafici',
        PATH: 'anagrafica',
        NAME: 'SommDistAnagrafica',
        TAB: { ...tabPropsDefault },
    },
})

export default [
    {
        path: ROUTES_SOMMDIST.SOMMLIST_LISTA.PATH,
        name: ROUTES_SOMMDIST.SOMMLIST_LISTA.NAME,
        component: SommDistList,
        meta: {
            title: ROUTES_SOMMDIST.SOMMLIST_LISTA.TITLE,
            permissions: {
                codPermesso: 'ANAG_SOMM',
                codVoceMenu: null,
            },
            tabGroup: tabPropsDefault.GROUP,
        },
        props: true,
    },
    {
        path: ROUTES_SOMMDIST.SOMMLIST_DETTAGLIO.PATH,
        name: ROUTES_SOMMDIST.SOMMLIST_DETTAGLIO.NAME,
        props: true,
        component: SommDistDettaglio,
        meta: {
            title: ROUTES_SOMMDIST.SOMMLIST_DETTAGLIO.TITLE,
            permissions: {
                codPermesso: 'ANAG_SOMM',
                codVoceMenu: null,
            },
            tabGroup: tabPropsDefault.GROUP,
        },
        children: [
            {
                label: ROUTES_SOMMDIST.SOMMLIST_ANAGRAFICA.LABEL,
                path: ROUTES_SOMMDIST.SOMMLIST_ANAGRAFICA.PATH,
                name: ROUTES_SOMMDIST.SOMMLIST_ANAGRAFICA.NAME,
                tab: ROUTES_SOMMDIST.SOMMLIST_ANAGRAFICA.TAB,
                orderWizard: 0,
                component: Childs.SommDistAnagrafica,
                meta: {
                    group: 'anagraficaSommDist',
                    permissions: {
                        codPermesso: 'ANAG_SOMM',
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },

                props: propsFn,
            },
        ],
    },
]
