<template>
    <div
        :id="_uid"
        class="base-datepicker"
    >
        <Datepicker
            v-bind="attrs"
            ref="datePicker"
            :action-row="{ showNow: mask !== 'dateMonth' }"
            cancel-text="Chiudi"
            close-on-scroll
            :dark="globalStore.state.darkTheme"
            :enable-time-picker="mask === 'dateTime'"
            locale="it-IT"
            :max-date="maxDate"
            :min-date="minDate"
            :year-range="[1900, 9999]"
            model-type="yyyy-MM-dd'T'HH:mm:ss"
            :month-picker="mask === 'dateMonth'"
            now-button-label="Oggi"
            position="left"
            :preview-format="previewText"
            select-text="Seleziona"
            :teleport="true"
            :text-input="{ openMenu: 'toggle' }"
            :time-picker-inline="mask === 'dateTime'"
            :uid="_uid"
            @closed="showMenu = false"
            @open="showMenu = true"
            @update:model-value="updateValue"
        >
            <template #trigger>
                <BaseInputText
                    :class="{ datetime: mask === 'dateTime' }"
                    :disabled="attrs.disabled"
                    :error-messages="attrs.errorMessages"
                    :label="attrs.label"
                    :mask="mask"
                    :messages="messages"
                    :model-value="attrs.modelValue"
                    :readonly="attrs.readonly"
                    :warning-messages="warnings"
                    @blur="closeMenu"
                    @change="value => updateValue(value.currentValue, false)"
                >
                    <template #append-inner>
                        <v-icon
                            icon="far fa-calendar"
                            size="small"
                            @click="toggleMenu"
                        />
                    </template>
                </BaseInputText>
            </template>
        </Datepicker>
    </div>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, ref, useAttrs } from 'vue'
import { castArray, get } from 'lodash'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import { useGlobalStore } from '@/stores'
import BaseInputText from '@/components/base/BaseInputText.vue'
import dateUty from '@/scripts/services/date'

export interface IBaseDatePickerProps {
    mask?: 'date' | 'dateMonth' | 'dateTime' | 'time' | undefined
    warningMessages?: string | string[]
}

const props = withDefaults(defineProps<IBaseDatePickerProps>(), {
    mask: 'date',
    warningMessages: () => [],
})

const attrs = useAttrs()
const globalStore = useGlobalStore()

const emits = defineEmits(['change', 'update:model-value'])

const _uid = ref(getCurrentInstance()!.uid).value.toString()
const datePicker = ref()
const showMenu = ref(false)

const maxDate = computed(() => get(attrs, 'max-date', '9999-12-31') as string)
const minDate = computed(() => get(attrs, 'min-date', '1900-01-01') as string)

const messages = computed(() => {
    return !warnings.value.length ? castArray(attrs.messages || []).slice(0, 1) : []
})

const warnings = computed(() => {
    const errors = (attrs.errorMessages as string[]) || []
    return !errors.length ? castArray(props.warningMessages || []).slice(0, 1) : []
})

function closeMenu(event: FocusEvent) {
    if (['I', 'INPUT'].includes((event.relatedTarget as HTMLElement)?.tagName)) {
        datePicker.value.closeMenu()
    }
}

function previewText(date: Date): string {
    const dd = date.getDate().toString().padStart(2, '0')
    const mm = (date.getMonth() + 1).toString().padStart(2, '0')
    const aa = date.getFullYear().toString().padStart(2, '0')
    return (props.mask === 'dateMonth' ? '' : `${dd}/`) + `${mm}/${aa}`
}

function toggleMenu(event: PointerEvent) {
    datePicker.value.toggleMenu()
    event.stopImmediatePropagation()
}

function updateValue(value: string | null, setInputFocus = true) {
    if (value) {
        // se arriva qui vuol dire che la data in BaseInputText è in fase di compilazione e non
        // ancora completa: non vanno emessi eventi
        if (!dateUty.dateOk(value)) return
    } else {
        // data vuota
        value = null
    }
    emits('update:model-value', value)
    emits('change', { currentValue: value, oldValue: attrs.modelValue })
    if (setInputFocus) {
        const input: HTMLElement | null | undefined = document
            .getElementById(_uid)
            ?.querySelector('.base-input-text .v-field__input input')
        input?.focus()
    }
}
</script>

<style lang="scss">
@import '@/styles/global/mixins';
@import '@/styles/global/vars';
.base-datepicker {
    .base-input-text {
        .v-input {
            min-width: 160px;
        }
        &.datetime .v-input {
            min-width: 200px;
        }
    }
}
.dp__menu {
    border: none;
    &:not(.dp__theme_dark) {
        background-color: #fafafa;
    }
    &.dp__theme_dark .dp__month_year_select {
        color: white;
    }
    .dp__overlay_container.dp__container_flex {
        height: inherit !important;
    }
    &.dp__menu_index {
        z-index: $zIndexDpMenu !important;
    }
}
</style>
