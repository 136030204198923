import { useGlobalStore } from '@/stores'
import FormuleViews from '@paghe/views/tabelle/formule'

const tabPropsDefault: TabProps = {
    GROUP: 'Formule',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'Formule',
    CODVOCEMENU: '000029',
    CODPERMESSO: ['TABELLE_SP', 'TABELLE_STUDIO'], //DOVREBBE ESSERE UN ARRAY
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route) {
    return {
        ...route.params,
        year: Number(route.params?.year ?? useGlobalStore().state.periodoElab!.year),
        month: Number(route.params?.month ?? useGlobalStore().state.periodoElab!.month),
        attualeCheck: Number(route.params?.attualeCheck ?? 1),
        allValidate: Number(route.params?.allValidate ?? 0),
    }
}

export const SETTINGS = Object.freeze({
    DETTAGLIO: {
        TITLE: ' Tabelle di domino - Gestione - Codici Diario',
        LABEL: 'Formule',
        PATH: 'tabelleFormule/:year?/:month?/:attualeCheck?/:allValidate?/',
        NAME: 'DettaglioFormule',
        TAB: { ...tabPropsDefault },
    },
    FORMULE: {
        TITLE: 'Tabelle di domino - Gestione - Codici Diario',
        LABEL: 'Formule',
        PATH: 'formule',
        NAME: 'TabelleFormule',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            component: FormuleViews.dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
                defaultProps: {
                    year: () => useGlobalStore().state.periodoElab!.year,
                    month: () => useGlobalStore().state.periodoElab!.month,
                    attualeCheck: 1,
                    allValidate: 0,
                },
            },
            props: propsFn,
            children: [
                {
                    path: SETTINGS.FORMULE.PATH,
                    name: SETTINGS.FORMULE.NAME,
                    component: FormuleViews.childs.formule,
                    meta: {
                        title: SETTINGS.FORMULE.TITLE,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: tabPropsDefault.CODVOCEMENU,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'TFORM',
                    },
                    props: propsFn,
                },
            ],
        },
    ],
}
