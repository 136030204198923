import formule from './formule'
import gruppoRisultato from './gruppo-risultato'
import minimaleMassimale from './minimaleMassimale'
import scadenza from './scadenza'
import scadenzaCodice from './scadenza-codice'

export default {
    formule,
    gruppoRisultato,
    minimaleMassimale,
    scadenza,
    scadenzaCodice,
}
