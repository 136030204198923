import { AxiosResponse } from 'axios'

import { call } from '@/scripts/lib/http'

const BASE_URL = '/EseguiExport'

const defaultPayload: Payload = {
    requireTotalCount: false,
    isCountQuery: false,
}

class API {
    @call({ url: `${BASE_URL}/ExportDipendenteJobExcel`, defaultPayload })
    static async dipendente<T>(payload: T): Promise<AxiosResponse<BasicResponsePayload<T>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/ExportDittaJobExcel`, defaultPayload })
    static async ditta<T>(payload: T): Promise<AxiosResponse<BasicResponsePayload<T>> | null> {
        return null
    }
}

export default {
    dipendente: API.dipendente<EseguiExportPayload>,
    ditta: API.ditta<EseguiExportPayload>,
}
