import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/CausaleEvento'

class API {
    @call({ url: `${BASE_URL}/GetCausaleEvento` })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<causaliEvento>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveCausaleEvento` })
    static async save(
        payload: SaveAliquotaPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveCausaleEvento>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
