import { GLOBALS } from '@/constants'
import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    GROUP: 'uniemensDipte',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'UNIEMENS DIPTE',
    CODPERMESSO: 'RII_DIPTE',
}

function propsFn(route) {
    const params = {
        ...route.params,
        year: Number(route.params.year),
        month: Number(route.params.month),
        day: route.params.day ? 1 : Number(route.params.day),
        dittaId: Number(route.params.dittaId),
        dittaPadreId: Number(route.params.dittaPadreId),
        mod: route.params.mod ?? GLOBALS.DATA_MODE.MOD,
        id: Number(route.params.id),
    }

    if (route.name != SETTINGS.LISTA.NAME) {
        params.mod = route.params.mod ?? GLOBALS.DATA_MODE.MOD
        params.id = Number(route.params.id)
    }

    return params
}

export const SETTINGS = Object.freeze({
    LISTA: {
        TITLE: 'Elenco Dipendenti - Uniemens - Dipendente - Gestione - Paghe',
        LABEL: 'Elenco Dipendenti Uniemens',
        PATH: 'uniemensDipte/:year/:month/:day/:dittaId/:dittaPadreId',
        NAME: 'uniemensElencoDipte',
        TAB: { ...tabPropsDefault },
    },
    DETTAGLIO: {
        TITLE: 'Dettaglio Dipendenti - Uniemens - Dipendente - Gestione - Paghe',
        LABEL: 'Dettaglio dipendente',
        PATH: 'uniemensDipte/:year/:month/:day/:dittaId/:dittaPadreId/:id/:mod',
        NAME: 'uniemensDipteDettaglio',
        TAB: { ...tabPropsDefault },
    },
    GENERALE: {
        TITLE: '',
        LABEL: 'Generale',
        PATH: 'generale',
        NAME: 'uniemensGenerale',
        TAB: { ...tabPropsDefault },
    },
    IMPONIBILE: {
        TITLE: '',
        LABEL: 'Imponibile / Contributi',
        PATH: 'imponibile',
        NAME: 'uniemensImponibile',
        TAB: { ...tabPropsDefault },
    },
    SETTIMANA: {
        TITLE: '',
        LABEL: 'Settimane / Differenze accredito',
        PATH: 'settimane',
        NAME: 'uniemensSettimane',
        TAB: { ...tabPropsDefault },
    },

    INFO_AGG_CAUSALI_CONTRIB_EVENTO_CIG: {
        TITLE: '',
        LABEL: 'Eventi CIG/Info agg. causali contributi',
        PATH: 'InfoAggCausaliContribEventiCig',
        NAME: 'uniemensInfoAggCausaliContribEventiCig',
        TAB: { ...tabPropsDefault },
    },

    PREAVVISO_BONUS_ESTERO: {
        TITLE: '',
        LABEL: 'Preavviso / Bonus / Estero',
        PATH: 'PreavvisoAppaltiBonusEstero',
        NAME: 'uniemensPreavvisoAppaltiBonusEstero',
        TAB: { ...tabPropsDefault },
    },

    CONTRIB_SIND_CONTR_ATIPICA: {
        TITLE: '',
        LABEL: 'Contrib.Sind / Contr.Atipica',
        PATH: 'ContribSindContrAtipica',
        NAME: 'uniemensContribSindContrAtipica',
        TAB: { ...tabPropsDefault },
    },

    LAV_DOMICILIO: {
        TITLE: '',
        LABEL: ' Lav.Domicilio',
        PATH: 'LavDomicilio',
        NAME: 'uniemensLavDomicilio',
        TAB: { ...tabPropsDefault },
    },
    FONDI_SPECIALI: {
        TITLE: '',
        LABEL: 'Fondi Speciali',
        PATH: 'FondiSpeciali',
        NAME: 'uniemensFondiSpeciali',
        TAB: { ...tabPropsDefault },
    },
    TFR_MESE_TFR_DESTINAZIONE: {
        TITLE: '',
        LABEL: 'TFR Mese / TFR Destinazione',
        PATH: 'TfrMeseTfrDestinazione',
        NAME: 'uniemensTfrMeseTfrDestinazione',
        TAB: { ...tabPropsDefault },
    },

    SPORT_SPETTACOLO: {
        TITLE: '',
        LABEL: 'Sport / Spettacolo',
        PATH: 'SportSpettacolo',
        NAME: 'uniemensSportSpettacolo',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.LISTA.PATH,
            name: SETTINGS.LISTA.NAME,
            component: pagheViews.uniemens.dipendente.liste.Elenco, // TODO: DA CAMBIARE CON IL COMPONENTE GIUSTO
            meta: {
                title: SETTINGS.LISTA.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    /* codVoceMenu: tabPropsDefault.CODVOCEMENU, */
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            props: propsFn,
            //props: true,
        },
        {
            path: SETTINGS.DETTAGLIO.PATH,
            name: SETTINGS.DETTAGLIO.NAME,
            props: propsFn,
            component: pagheViews.uniemens.dipendente.dettaglio.Dettaglio,
            meta: {
                title: SETTINGS.DETTAGLIO.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            children: [
                {
                    label: SETTINGS.GENERALE.LABEL,
                    path: SETTINGS.GENERALE.PATH,
                    name: SETTINGS.GENERALE.NAME,
                    tab: SETTINGS.GENERALE.TAB,
                    component: pagheViews.uniemens.dipendente.dettaglio.childs.Generale,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNP01',
                    },
                    orderWizard: 0,
                    props: propsFn,
                },
                {
                    label: SETTINGS.IMPONIBILE.LABEL,
                    path: SETTINGS.IMPONIBILE.PATH,
                    name: SETTINGS.IMPONIBILE.NAME,
                    tab: SETTINGS.IMPONIBILE.TAB,
                    component: pagheViews.uniemens.dipendente.dettaglio.childs.Imponibile,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNP02',
                    },
                    orderWizard: 1,
                    props: propsFn,
                },
                {
                    label: SETTINGS.SETTIMANA.LABEL,
                    path: SETTINGS.SETTIMANA.PATH,
                    name: SETTINGS.SETTIMANA.NAME,
                    tab: SETTINGS.SETTIMANA.TAB,
                    component: pagheViews.uniemens.dipendente.dettaglio.childs.Settimana,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNP03',
                    },
                    orderWizard: 2,
                    props: propsFn,
                },
                {
                    label: SETTINGS.INFO_AGG_CAUSALI_CONTRIB_EVENTO_CIG.LABEL,
                    path: SETTINGS.INFO_AGG_CAUSALI_CONTRIB_EVENTO_CIG.PATH,
                    name: SETTINGS.INFO_AGG_CAUSALI_CONTRIB_EVENTO_CIG.NAME,
                    tab: SETTINGS.INFO_AGG_CAUSALI_CONTRIB_EVENTO_CIG.TAB,
                    component:
                        pagheViews.uniemens.dipendente.dettaglio.childs
                            .InfoAggCausaliContribEventiCig,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNP04',
                    },
                    orderWizard: 3,
                    props: propsFn,
                },
                {
                    label: SETTINGS.PREAVVISO_BONUS_ESTERO.LABEL,
                    path: SETTINGS.PREAVVISO_BONUS_ESTERO.PATH,
                    name: SETTINGS.PREAVVISO_BONUS_ESTERO.NAME,
                    tab: SETTINGS.PREAVVISO_BONUS_ESTERO.TAB,

                    component:
                        pagheViews.uniemens.dipendente.dettaglio.childs.PreavvisoAppaltiBonusEstero,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNP05',
                    },
                    orderWizard: 3,
                    props: propsFn,
                },
                {
                    label: SETTINGS.CONTRIB_SIND_CONTR_ATIPICA.LABEL,
                    path: SETTINGS.CONTRIB_SIND_CONTR_ATIPICA.PATH,
                    name: SETTINGS.CONTRIB_SIND_CONTR_ATIPICA.NAME,
                    tab: SETTINGS.CONTRIB_SIND_CONTR_ATIPICA.TAB,

                    component:
                        pagheViews.uniemens.dipendente.dettaglio.childs.ContribSindContrAtipica,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNP06',
                    },
                    orderWizard: 4,
                    props: propsFn,
                },
                {
                    label: SETTINGS.LAV_DOMICILIO.LABEL,
                    path: SETTINGS.LAV_DOMICILIO.PATH,
                    name: SETTINGS.LAV_DOMICILIO.NAME,
                    tab: SETTINGS.LAV_DOMICILIO.TAB,

                    component: pagheViews.uniemens.dipendente.dettaglio.childs.LavDomicilio,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNP07',
                    },
                    orderWizard: 5,
                    props: propsFn,
                },
                {
                    label: SETTINGS.FONDI_SPECIALI.LABEL,
                    path: SETTINGS.FONDI_SPECIALI.PATH,
                    name: SETTINGS.FONDI_SPECIALI.NAME,
                    tab: SETTINGS.FONDI_SPECIALI.TAB,

                    component: pagheViews.uniemens.dipendente.dettaglio.childs.FondiSpeciali,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNP08',
                    },
                    orderWizard: 6,
                    props: propsFn,
                },
                {
                    label: SETTINGS.TFR_MESE_TFR_DESTINAZIONE.LABEL,
                    path: SETTINGS.TFR_MESE_TFR_DESTINAZIONE.PATH,
                    name: SETTINGS.TFR_MESE_TFR_DESTINAZIONE.NAME,
                    tab: SETTINGS.TFR_MESE_TFR_DESTINAZIONE.TAB,

                    component:
                        pagheViews.uniemens.dipendente.dettaglio.childs.TfrMeseTfrDestinazione,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNP09',
                    },
                    orderWizard: 7,
                    props: propsFn,
                },

                {
                    label: SETTINGS.SPORT_SPETTACOLO.LABEL,
                    path: SETTINGS.SPORT_SPETTACOLO.PATH,
                    name: SETTINGS.SPORT_SPETTACOLO.NAME,
                    tab: SETTINGS.SPORT_SPETTACOLO.TAB,

                    component: pagheViews.uniemens.dipendente.dettaglio.childs.SportSpettacolo,
                    meta: {
                        group: SETTINGS.DETTAGLIO.NAME,
                        permissions: {
                            codPermesso: tabPropsDefault.CODPERMESSO,
                            codVoceMenu: null,
                        },
                        tabGroup: tabPropsDefault.GROUP,
                        idPaginaMsg: 'UNP10',
                    },
                    orderWizard: 8,
                    props: propsFn,
                },
            ],
        },
    ],
}
