//import * as common from './common'
import aliquote from './aliquote'
import causaliEvento from './causaliEvento'
import codiciDiario from './codiciDiario'
import contratto from './contratto'
import gruppiRisultati from './gruppiRisultati'
import retribuzioni from './retribuzioni'
import scadenze from './scadenze'
import varie from './varie'

export default {
    aliquote,
    causaliEvento,
    codiciDiario,
    contratto,
    gruppiRisultati,
    retribuzioni,
    scadenze,
    varie,
}
